import { Box, DialogContentText } from '@mui/material';
import DialogContentLabel from '../DialogContentLabel';
import { useTranslation } from 'react-i18next';
import { Alarm } from '@culligan-iot/domain/culligan/device/capability/alarm';

export default function IssueDescription({ data }: { data: { alarm: Alarm } }) {
  const { t } = useTranslation();
  return (
    <Box>
      <DialogContentLabel>{t('description')}:</DialogContentLabel>
      <Box display="flex" gap={'1rem'} alignItems="baseline">
        <DialogContentText>{data.alarm.constructor.description}</DialogContentText>
      </Box>
    </Box>
  );
}
