import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import dayjs from 'dayjs';

function InstallationDate({ device }: { device: Device }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Area
        sx={{
          width: '100%',
          gridColumnStart: '1',
          gridColumnEnd: '4',
          gridRowStart: '1',
          gridRowEnd: '-1',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <AreaHeader>
          <TypographyWithTooltip
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            anchorText={t('installationDate')}
            tooltipText={t('installationDateTooltip')}
          />
        </AreaHeader>
        <AreaBody>
          <Title>{device.installedAt ? dayjs(device.installedAt).format('YYYY/MM/DD') : t('notAvailable')}</Title>
        </AreaBody>
      </Area>
    </Fragment>
  );
}

export default React.memo(InstallationDate);
