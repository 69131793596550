import { Schema as S, Either } from 'effect';
import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FilterAlarmRequest } from '@/components/Fleet/AlarmsAndErrors/typings';
import { Alarm } from '@culligan-iot/domain/culligan/device/history';
import { paginatedResponseBodySuccess } from '@culligan-iot/domain/culligan/api/v2';
import { Page } from '@culligan-iot/domain/pagination';

const id = (x: any) => x;

const decodeFilteredAlarms = S.decodeUnknownEither(paginatedResponseBodySuccess(Alarm));

export const AlarmsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['FleetAlarms', 'Fleet'] }).injectEndpoints({
  endpoints: (builder) => ({
    getFilteredAlarms: builder.query<ApiResult<Page<Alarm>>, FilterAlarmRequest>({
      query: (params) => {
        return {
          url: `${API_VERSION.v2}/fleet/alarms/filteredList`,
          params,
        };
      },
      transformResponse: (response) => decodeFilteredAlarms(response).pipe(Either.getOrThrowWith(id)),
      providesTags: ['FleetAlarms'],
    }),
    getAlarmsFilters: builder.query<
      ApiResult<{ brands: { id: string; name: string }[]; identities: { id: string; name: string }[] }>,
      void
    >({
      query: () => {
        return {
          url: `${API_VERSION.v2}/fleet/alarms/filters`,
        };
      },
      providesTags: ['FleetAlarms'],
    }),
    getCustomer: builder.query<
      ApiResult<{ customer: { id: string; firstName: string; lastName: string } }>,
      { customerId: string }
    >({
      query: (params) => {
        return {
          url: `${API_VERSION.v2}/fleet/alarms/customer`,
          params,
        };
      },
      providesTags: ['FleetAlarms'],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetFilteredAlarmsQuery,
  useGetAlarmsFiltersQuery,
  useLazyGetFilteredAlarmsQuery,
  useLazyGetCustomerQuery,
  useGetCustomerQuery,
} = AlarmsApiSlice;
