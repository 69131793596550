/**
 * Temporary Feature Visibility Control
 *
 * JIRA: CO-863 (https://culligan.atlassian.net/jira/software/c/projects/CO/boards/117?selectedIssue=CO-863)
 *
 * Some features have been temporarily commented out in this component as they are
 * currently non-functional. This is a temporary measure until the features are fully
 * implemented and tested.
 *
 */
import { alpha, Box, keyframes, Paper, Theme } from '@mui/material';
import { ACTION_STATUS } from '../constants';
import { OccupiedSlotProps } from '../typings';
import LoadingFeedback from '../LoadingFeedback';
import ErrorFeedback from '../ErrorFeedback';
import SuccessFeedback from '../SuccessFeedback';
import { AreaRow } from '@/components/Shared/Card/Area';
import { useTranslation } from 'react-i18next';
import { CropFree, Repeat } from '@mui/icons-material';
import { DeviceConsumable } from '../../../DevicesPanel/typings';
import SlotFooter from './Base/SlotFooter';
import { buildNumberFormatter } from '@/shared/utils';
import Identity from './Area/Identity';
import Expiration from './Area/Expiration/Expiration';
import Level from './Area/Level';
import { useSlotSpecs } from './useSlotSpecs';
import { isExpirable } from '@culligan-iot/domain/culligan/device/capability/consumable';
import Installation from './Area/Installation';

const boxShadowAnimation = (theme: Theme) => keyframes`
0% {
  box-shadow: 0px 0px 5px ${alpha(theme.palette.error.light, 0.2)};
}
50% {
  box-shadow: 0px 0px 10px ${alpha(theme.palette.error.light, 0.5)};
}
100% {
  box-shadow: 0px 0px 5px ${alpha(theme.palette.error.light, 0.2)};
}
`;

const shakeAnimation = () => keyframes`
0% { transform: translateX(0) }
12.5% { transform: translateX(3px) }
25% { transform: translateX(-3px) }
37.5% { transform: translateX(3px) }
50% { transform: translateX(0) }
100% { transform: translateX(0) }
`;

const round = (val: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(val * multiplier) / multiplier;
};

export const numberValueCircularBar = (percentageFill: number, consumable: DeviceConsumable) => {
  const subsetData = consumable.subset;

  if (percentageFill === 100) {
    return subsetData?.rangeFullCapacity;
  } else if (percentageFill === 0) {
    return subsetData?.rangeExhaust;
  } else {
    if (percentageFill < 10) {
      return round(consumable.current, 3);
    }
    return round(consumable.current, 2);
  }
};

const conditionalRoundTo3Decimal = (raw: number, value: string) => {
  const numeric = Number(value);

  if (numeric >= 10) {
    return String(round(numeric, 2));
  }

  return value;
};

const conditionalRoundTo2Decimal = (raw: number, value: string) => {
  const numeric = Number(value);

  if (numeric < 10) {
    return String(round(numeric, 3));
  }

  return value;
};

const conditionalMetricPrefixNotation = (raw: number, value: string) =>
  Number(value) >= 1000 ? `${round(Number(value) / 1000, 2)}k` : value;

export const formatDisplayFillLevel = (value: number) =>
  buildNumberFormatter([conditionalRoundTo2Decimal, conditionalRoundTo3Decimal, conditionalMetricPrefixNotation])(
    value
  )[0];

export default function OccupiedSlot({
  index,
  actionStatus,
  dismissErrorAnimation,
  setDismissErrorAnimation,
  performedAction,
  consumable,
  handleClickSetMax,
  handleClickSetMin,
  handleClickEdit,
  handleClickRemove,
  isDeviceConnected,
  slot,
}: OccupiedSlotProps) {
  const { t } = useTranslation();
  const { id, name, category } = useSlotSpecs(slot, consumable);
  // const percentageFill = wrapInRange(
  //   consumable?.state.installedAt &&  || 0,
  //   consumable?.subset?.subset?.rangeExhaust ?? 0,
  //   consumable?.subset?.subset?.rangeFullCapacity ?? 100
  // );

  return (
    <Paper
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: theme.palette.background.grayShades[0],
        display: 'grid',
        gridTemplate: '1fr auto / 1fr',
        maxWidth: '600px',
        width: '100%',
        borderRadius: 1,
        zIndex: 1,
        outline: `0px solid ${alpha(theme.palette.error.light, 0.2)}`,
        ...(actionStatus === ACTION_STATUS.Error && !dismissErrorAnimation
          ? {
              animation: `${boxShadowAnimation(theme)} 3000ms infinite alternate ${
                theme.transitions.easing.easeInOut
              }, ${shakeAnimation()} 1s infinite`,
            }
          : {}),
      })}
      elevation={1}
      onMouseOver={() =>
        actionStatus === ACTION_STATUS.Error && !dismissErrorAnimation && setDismissErrorAnimation(true)
      }
    >
      <LoadingFeedback loading={actionStatus === ACTION_STATUS.Loading} />
      <ErrorFeedback hasErrors={actionStatus === ACTION_STATUS.Error} action={performedAction} />
      <SuccessFeedback success={actionStatus === ACTION_STATUS.Success} />
      <Paper
        elevation={0}
        variant="outlined"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
          height: '100%',
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 2,
          borderRadius: 1,
          width: '100%',
        })}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 1, height: '100%', width: '100%' }}>
          <AreaRow sx={{ display: 'grid', gridTemplateRows: '1.5fr 1fr', gap: 1 }}>
            <Identity consumable={consumable} />
            {isExpirable(consumable.state) ? (
              <Expiration consumable={consumable} />
            ) : (
              <Installation consumable={consumable} />
            )}
          </AreaRow>
          <Level
            consumable={consumable}
            isOnline={isDeviceConnected}
            handleSetMax={handleClickSetMax}
            handleSetMin={handleClickSetMin}
          />
        </Box>
      </Paper>
      <SlotFooter
        actions={[
          {
            icon: <CropFree sx={{ mr: '0.2rem' }} />,
            label: t('change'),
            onClick: handleClickEdit,
            disabled: true, //!isDeviceConnected,
          },
          {
            icon: <Repeat sx={{ mr: '0.2rem' }} />,
            label: t('remove'),
            onClick: handleClickRemove,
            color: 'error',
            disabled: true, //!isDeviceConnected,
          },
        ]}
        category={category}
        id={id}
        index={index}
        isDeviceConnected={isDeviceConnected}
        name={name}
      />
    </Paper>
  );
}
