import { Avatar, Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BlipChip from '../../Chips/BlipChip';
import dayjs from 'dayjs';
import { IssueDetails } from './types';
import { Match } from 'effect';

export default function IssueDismiss({ data }: { data: IssueDetails.Data }) {
  const { t } = useTranslation();

  const dismissedAt = Match.value(data).pipe(
    Match.when({ _tag: 'FleetOverviewAlarm' }, () => undefined),
    Match.when({ _tag: 'FleetOverviewError' }, () => undefined),
    Match.when({ _tag: 'FleetDevice' }, () => undefined),
    Match.when({ _tag: 'FleetAlarms' }, ({ dismissedAt }) => dismissedAt),
    Match.when({ _tag: 'FleetError' }, ({ dismissedAt }) => dismissedAt),
    Match.exhaustive
  );

  return (
    <Box>
      {dismissedAt && (
        <Chip
          avatar={
            <Avatar sx={{ bgcolor: 'transparent' }}>
              <Box
                sx={(theme) => ({
                  height: '80%',
                  bgcolor: theme.palette.success.light,
                  width: '80%',
                  borderRadius: '50%',
                })}
              />
            </Avatar>
          }
          label={t('resolved')}
          size="small"
          color="success"
          variant="outlined"
        />
      )}
      {!dismissedAt && <BlipChip label={t('active')} size="small" color="error" variant="outlined" />}
      {dismissedAt && (
        <>
          <br />
          <Typography variant="caption">{dayjs(dismissedAt).utc().fromNow()}</Typography>
        </>
      )}
    </Box>
  );
}
