import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EventRes } from './typings';
import ExpandableField from '@/components/Shared/ExpandableField';
import { useNavigate } from 'react-router';
import { getPath } from '@/shared/utils';

export default function useEventsColumns(itemsInDeviceIdentity?: EventRes[]): Array<Column<EventRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data_columns_array: Array<Column<EventRes>> = [
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('parameters'),
      field: 'parameters',
      render: (events) => <Typography>{events.parameters ? t('yes') : t('no')}</Typography>,
    },
    {
      title: t('payloadExample'),
      width: '15%',
      field: 'payloadExample',
      render: (events) => events.payloadExample && <ExpandableField content={events.payloadExample} />,
      sorting: false,
    },
  ];

  const actions_columns_array: Array<Column<EventRes>> = itemsInDeviceIdentity
    ? [
        {
          title: t('actions'),
          width: '10%',
          render: (events) => (
            <Box display="flex" justifyContent="flex-start" gap="0.5rem">
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  navigate(getPath('ONETOOL_CHANNELS_EVENTS'), {
                    state: {
                      searchText: events.name,
                      highlight: {
                        refField: 'id',
                        matchValue: events.id,
                      },
                    },
                  })
                }
              >
                {t('view')}
              </Button>
            </Box>
          ),
          sorting: false,
          searchable: false,
        },
      ]
    : [];

  return [...data_columns_array, ...actions_columns_array];
}
