import { Autocomplete, Button, debounce, TextField, Box, Alert } from '@mui/material';
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm, FormProvider, useFormContext, FieldValues } from 'react-hook-form';
import {
  usePutDeviceInstallationAddressMutation,
  useDeleteDeviceInstallationAddressMutation,
  PutInstallationAddress,
} from '@/redux/api/fleet/devicesApiSlice';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { identity } from 'effect';
import { Props, createAddressFromGeocoderResult, Address, makeDefaultValues } from './utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import dayjs from 'dayjs';
import { effectTsResolver } from '@hookform/resolvers/effect-ts';

type AddressOption = google.maps.GeocoderResult;

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
type PutInstallationAddress = typeof PutInstallationAddress.Type;

const AsyncAutocomplete = <T extends FieldValues, O extends Object>({
  name,
  options,
  handleChange,
  handleInputChange,
  isOptionEqualToValue,
  getOptionLabel,
  isQuerying,
}: Props<T, O>) => {
  const { control } = useFormContext<T>();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, ref, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            loading={isQuerying}
            loadingText="Searching..."
            noOptionsText="Type to search for addresses"
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={identity}
            value={value ? options.find((opt) => getOptionLabel(opt) === value) ?? null : null}
            onChange={(_, newValue) => {
              onChange(handleChange(newValue));
            }}
            onInputChange={async (_, newInputValue, reason) => {
              if (reason === 'input') {
                handleInputChange(newInputValue);
              }
            }}
            onBlur={onBlur}
            renderInput={(params) => (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                <TextField
                  {...params}
                  inputRef={ref}
                  label="Search address"
                  margin="dense"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              </Box>
            )}
          />
        );
      }}
    />
  );
};

export const AddressForm = ({
  closeModal,
  serialNumber,
  currentAddress,
}: {
  closeModal: () => void;
  serialNumber: string;
  currentAddress?: {
    installationAddress: Device['installationAddress'];
    providedLocation: Device['providedLocation'];
  } | null;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [putInstallationAddress, flags] = usePutDeviceInstallationAddressMutation();
  const [deleteAddress] = useDeleteDeviceInstallationAddressMutation();
  const [addresses, setAddresses] = useState<AddressOption[]>([]);
  const [isQuerying, setIsQuerying] = useState(false);
  const geocoding = useRef<google.maps.Geocoder | null>(new window.google.maps.Geocoder());

  const methods = useForm<PutInstallationAddress>({
    resolver: effectTsResolver(PutInstallationAddress),
    defaultValues: async () => {
      const formDefaultValues = makeDefaultValues(currentAddress);

      if (geocoding.current != null && currentAddress?.installationAddress?.address) {
        const { results } = await geocoding.current.geocode({
          address: currentAddress.installationAddress.address,
        });
        /**
         * No geocoding results found with the current provided address.
         */
        if (results.length === 0) {
          return Promise.resolve(formDefaultValues);
        }

        /**
         * Update the addresses list with the found addresses.
         */
        setAddresses(results);

        const currentAddressOption = results[0];
        const currentAddressFormatted = currentAddressOption.formatted_address;

        setValue('installationAddress.address', currentAddressFormatted);

        return Promise.resolve({
          installationAddress: {
            ...formDefaultValues.installationAddress,
            address: currentAddressFormatted,
          },
          providedLocation: formDefaultValues.providedLocation,
        });
      }
      return Promise.resolve(formDefaultValues);
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const {
    reset,
    setValue,
    setError,
    watch,
    formState: { isSubmitting, isValid },
    getValues,
  } = methods;

  const handleQueryChange = useCallback(
    async (query: string) => {
      if (!geocoding.current) {
        setError('installationAddress.address', {
          type: 'manual',
          message: 'Geocoding service not available',
        });
        return;
      }
      setIsQuerying(true);

      try {
        const { results } = await geocoding.current.geocode({ address: query });
        setAddresses(results);
      } catch (error) {
        // Maps throws even on ZERO RESULTS, ignoring.
      } finally {
        setIsQuerying(false);
      }
    },
    [setError]
  );

  const handleAddressChangeDebounced = useMemo(() => debounce(handleQueryChange, 500), [handleQueryChange]);
  const _addressValue = watch('installationAddress.address');

  useEffect(() => {
    if (!_addressValue) {
      setValue('installationAddress.city', '');
      setValue('installationAddress.zip', '');
      setValue('installationAddress.state', '');
      setValue('installationAddress.country', '');
      setValue('installationAddress.countryCode', '');
      setValue('providedLocation', null);
    }
  }, [_addressValue, setValue, watch]);

  const handleSelection = useCallback(
    (result: AddressOption | null) => {
      if (!result) {
        return '';
      }
      try {
        const { installationAddress, providedLocation } = createAddressFromGeocoderResult(result);

        setValue('installationAddress', {
          ...installationAddress,
          addressExtra: getValues().installationAddress.addressExtra ?? installationAddress.addressExtra,
        });
        setValue('providedLocation', providedLocation);

        return installationAddress.address;
      } catch (error) {
        console.error('Error while creating address from geocoder result', error);
        return '';
      }
    },
    [getValues, setValue]
  );

  const createNotification = (
    status: (typeof STATUS)[keyof typeof STATUS],
    tag: (typeof TAGS)[keyof typeof TAGS],
    error?: any
  ) => {
    dispatch(
      upsertOperation({
        entity: serialNumber,
        location: currentAddress?.installationAddress?.address ?? 'New Address',
        operationId: flags.requestId ?? '',
        read: false,
        showed: false,
        subject: 'Device Address',
        timestamp: dayjs().valueOf(),
        tag,
        state: status === STATUS.SUCCESS ? 'fulfilled' : 'rejected',
        status,
        uniqueId: `${flags?.requestId}-${STATUS.INFO}`,
        error: error ?? null,
      })
    );
  };

  const handleDelete = async () => {
    if (isSubmitting) return;

    try {
      await deleteAddress({
        serialNumber,
      }).unwrap();
      createNotification(STATUS.SUCCESS, TAGS.DEVICE_ADDRESS_DELETE);
      closeModal();
      reset();
    } catch (error) {
      createNotification(STATUS.ERROR, TAGS.DEVICE_ADDRESS_DELETE, error);
    }
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    const formValues = getValues();

    try {
      await putInstallationAddress({
        serialNumber,
        addressAndProvidedLocation: {
          ...formValues,
        },
      }).unwrap();
      createNotification(
        STATUS.SUCCESS,
        currentAddress?.installationAddress?.address ? TAGS.DEVICE_ADDRESS_UPDATE : TAGS.DEVICE_ADDRESS_CREATE
      );
      closeModal();
    } catch (error) {
      createNotification(
        STATUS.ERROR,
        currentAddress?.installationAddress?.address ? TAGS.DEVICE_ADDRESS_UPDATE : TAGS.DEVICE_ADDRESS_CREATE,
        error
      );
      setError('root', { type: 'manual', message: 'Failed to update address' });
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        noValidate
      >
        {methods.formState.errors.root && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {methods.formState.errors.root.message}
          </Alert>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <AsyncAutocomplete<PutInstallationAddress, AddressOption>
            name="installationAddress.address"
            options={addresses}
            handleChange={(option) => {
              if (option) {
                return handleSelection(option);
              }
              return null;
            }}
            handleInputChange={handleAddressChangeDebounced}
            getOptionLabel={Address.getLabel}
            isOptionEqualToValue={(a, b) => a.formatted_address === b.formatted_address}
            isQuerying={isQuerying}
          />

          <Controller
            name="installationAddress.addressExtra"
            control={methods.control}
            render={({ field }) => (
              <TextField {...field} value={field.value ?? ''} label="Additional Address Info" fullWidth />
            )}
          />

          <Box
            sx={{
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <Controller
              name="installationAddress.city"
              control={methods.control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    value={field.value ?? ''}
                    label="City"
                    sx={{ marginBottom: 2 }}
                    fullWidth
                    helperText={fieldState?.error?.message ?? ''}
                    error={!!fieldState.error?.message}
                  />
                );
              }}
            />

            <Controller
              name={'installationAddress.zip'}
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label={t('deviceAddress.zip_code')}
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  size="medium"
                  helperText={fieldState?.error?.message ?? ''}
                  error={!!fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={'installationAddress.state'}
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label={t('deviceAddress.state')}
                  size="medium"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  helperText={fieldState?.error?.message ?? ''}
                  error={!!fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="installationAddress.country"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label={t('deviceAddress.country')}
                  size="medium"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  helperText={fieldState?.error?.message ?? ''}
                  error={!!fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="installationAddress.countryCode"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label={t('deviceAddress.country_code')}
                  size="medium"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  helperText={fieldState?.error?.message ?? ''}
                  error={!!fieldState.error?.message}
                />
              )}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2, mt: 2 }}>
            <Button variant="outlined" onClick={closeModal} disabled={isSubmitting}>
              {t('cancel')}
            </Button>
            {currentAddress?.installationAddress?.address ? (
              <>
                <Button variant="outlined" color="error" onClick={handleDelete} disabled={isSubmitting}>
                  {t('remove')}
                </Button>
                <Button variant="contained" type="submit" disabled={isSubmitting || !isValid}>
                  {t('update')}
                </Button>
              </>
            ) : (
              <Button type="submit" variant="contained" disabled={!isValid || isSubmitting}>
                {t('save')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AddressForm;
