import { Record, Array } from 'effect';
import { Culligan } from '@culligan-iot/domain';

const isQuench = (vendor: Culligan.Device.Class.Device['vendor']): vendor is Culligan.Device.Vendor.Quench.DeviceInfo =>
  vendor.code === 'qnc';

/** Device models */
const deviceModelCodes: Culligan.Device.Class.Device['constructor']['code'][] = Record.values(
  Culligan.Device.Class.identities
).map(({ code }) => code);

const deviceModelLabels: Culligan.Device.Class.Device['constructor']['label'][] = Record.values(
  Culligan.Device.Class.identities
).map(({ label }) => label);

const deviceLabelByModel: Record<
  Culligan.Device.Class.Device['constructor']['code'],
  Culligan.Device.Class.Device['constructor']['label']
> = Record.fromEntries(Record.values(Culligan.Device.Class.identities).map(({ code, label }) => [code, label]));
/** */

const deviceVendorCodes = Culligan.Device.Vendor.Code.members.map(({ literals: [code] }) => code);

const deviceVendorNames = Culligan.Device.Vendor.DeviceInfo.members.map(({ label }) => label);

const deviceVendorsCodesAndNames = Record.fromEntries(Array.zip(deviceVendorCodes, deviceVendorNames));

export {
  isQuench,
  deviceModelCodes,
  deviceModelLabels,
  deviceLabelByModel,
  deviceVendorCodes,
  deviceVendorNames,
  deviceVendorsCodesAndNames,
};
