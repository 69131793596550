/* eslint-disable no-redeclare */
import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import RelatedEntityLabel from '@/components/Shared/Chips/RelatedEntityChip';
import TagIcon from '@mui/icons-material/Tag';
import { SEVERITY } from '@/shared/constants';
import BlipChip from '@/components/Shared/Chips/BlipChip';

import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { columnsBuilder, defaultSearchByDate, withExcludedColumns } from '@/components/Shared/Tables/utils';
import { TFunction } from 'i18next';
import { Fragment, useCallback } from 'react';
import { getPath } from '@/shared/utils';
import { AlarmContext } from './AlarmsTable';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { Alarm } from '@culligan-iot/domain/culligan/device/history';
import { getAlarmLabel } from '@/shared/domain/alarm';
import { FleetOverview } from '../../Overview/typings';

type UseAlarmColumnsProps<K extends AlarmContext> = {
  kind: K;
  onDetailClick?: (deviceId: string) => void;
  excludeColumns?: Array<string>;
};

const renderAlarmParameters = (alarm: Alarm['alarm'], t: TFunction<'translation', undefined, 'translation'>) => {
  const hasParams = 'params' in alarm && typeof alarm.params === 'object';
  if (!hasParams) return;

  return (
    <Box display={'flex'} flexWrap={'wrap'} gap={1}>
      {Object.entries(alarm.params).map(([key, value]) => {
        const label = (
          <Typography variant="body2">
            {t(key)}:{' '}
            <Typography component={'span'} fontWeight={'bold'} variant="body2">
              {value}
            </Typography>
          </Typography>
        );
        return <Chip size="small" key={`${key}${value}`} label={label} />;
      })}
    </Box>
  );
};

const renderAlarmReportDate = ({ createdAt }: { createdAt: Date }) => (
  <Fragment>
    <Typography>{createdAt.toLocaleString()}</Typography>
    <Typography variant="caption">{dayjs(createdAt).utc().fromNow()}</Typography>
  </Fragment>
);

const renderAlarmName = ({
  alarm: {
    constructor: { description },
    _tag,
  },
}: Pick<Alarm, 'alarm'>) => (
  <>
    <Tooltip title={description}>
      <Typography>{getAlarmLabel(_tag)}</Typography>
    </Tooltip>
    <Chip icon={<TagIcon />} label={_tag} size="small" />
  </>
);

export function useAlarmColumns<K extends AlarmContext>(
  props: UseAlarmColumnsProps<K>
): Column<Device['alarms'][number]>[] | Column<Alarm>[] | Column<FleetOverview.Alarm>[] {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (deviceId: string) => {
      navigate(getPath('FLEET_DEVICE', { deviceId }));
    },
    [navigate]
  );

  switch (props.kind) {
    case 'device':
      return withExcludedColumns(buildDeviceAlarmsColumns(t, props.onDetailClick), props.excludeColumns);
    case 'fleet':
      return withExcludedColumns(buildFleetAlarmsColumns(t, handleNavigate, props.onDetailClick), props.excludeColumns);
    case 'overview':
      return withExcludedColumns(
        buildFleetOverviewAlarmsColumns(t, handleNavigate, props.onDetailClick),
        props.excludeColumns
      );
    default:
      throw new Error(`Unsupported alarm kind: ${props.kind}`);
  }
}

const buildFleetAlarmsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleNavigateDevice: (deviceId: string) => void,
  handleDetail?: (id: Alarm['id']) => void
): Column<Alarm>[] => {
  const builder = columnsBuilder<Alarm>();

  builder
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('severity')} tooltipText={t('severityTooltip')} />,
      field: 'severity',
      exportTransformer: ({
        alarm: {
          constructor: { severity },
        },
      }) => t(severity || 'unknown'),
      customExport: ({
        alarm: {
          constructor: { severity },
        },
      }) => t(severity || 'unknown'),
      render: ({
        alarm: {
          constructor: { severity },
        },
      }) =>
        severity ? (
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: 'transparent' }}>
                <Box
                  sx={(theme) => ({
                    height: '80%',
                    bgcolor: severity === SEVERITY.CRITICAL ? theme.palette.warning.dark : theme.palette.caution.dark,
                    width: '80%',
                    borderRadius: '50%',
                  })}
                />
              </Avatar>
            }
            label={t(severity)}
            size="small"
            variant="filled"
            color={severity === SEVERITY.CRITICAL ? 'warning' : 'caution'}
          />
        ) : (
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: 'transparent' }}>
                <Box
                  sx={(theme) => ({
                    height: '80%',
                    bgcolor: theme.palette.grey[600],
                    width: '80%',
                    borderRadius: '50%',
                  })}
                />
              </Avatar>
            }
            label={t('unknown')}
            size="small"
            color="default"
            variant="filled"
          />
        ),
    })
    .addColumn({
      title: t('reportingDate'),
      field: 'createdAt',
      exportTransformer: ({ createdAt }) => createdAt.toLocaleString(),
      render: renderAlarmReportDate,
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      exportTransformer: ({ alarm: { _tag } }) => getAlarmLabel(_tag),
      render: renderAlarmName,
    })
    .addColumn({
      title: t('device'),
      field: 'deviceId',
      exportTransformer: ({ deviceId }) => deviceId,
      render: ({ deviceId }) => (
        <RelatedEntityLabel
          text={deviceId || (t('device') as string)}
          onEntityClick={() => handleNavigateDevice(deviceId)}
        />
      ),
    })
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('status')} tooltipText={t('statusTooltip')} />,
      field: 'dismissedAt',
      exportTransformer: ({ dismissedAt }) => (dismissedAt ? 'resolved' : 'active'),
      render: ({ dismissedAt }) => (
        <>
          {dismissedAt && (
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <Box
                    sx={(theme) => ({
                      height: '80%',
                      bgcolor: theme.palette.success.light,
                      width: '80%',
                      borderRadius: '50%',
                    })}
                  />
                </Avatar>
              }
              label={t('resolved')}
              size="small"
              color="success"
              variant="outlined"
            />
          )}
          {!dismissedAt && <BlipChip label={t('active')} size="small" color="error" variant="outlined" />}
          {dismissedAt && (
            <>
              <br />
              <Typography variant="caption">{dayjs(dismissedAt).utc().fromNow()}</Typography>
            </>
          )}
        </>
      ),
    })
    .addColumn({
      title: t('parameters'),
      field: 'params',
      render: ({ alarm }) => renderAlarmParameters(alarm, t),
    })
    .addColumn({
      title: t('actions'),
      render: ({ id }) => (
        <Box display="flex" gap={1}>
          <Button variant="outlined" color="primary" onClick={() => handleDetail && handleDetail(id)}>
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    });

  return builder.columns;
};

const buildDeviceAlarmsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleDetail?: (id: Device['alarms'][number]['id']) => void
): Column<Device['alarms'][number]>[] => {
  const builder = columnsBuilder<Device['alarms'][number]>();

  builder
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('severity')} tooltipText={t('severityTooltip')} />,
      field: 'severity',
      exportTransformer: ({
        alarm: {
          constructor: { severity },
        },
      }) => t(severity),
      customExport: ({
        alarm: {
          constructor: { severity },
        },
      }) => t(severity),
      render: ({
        alarm: {
          constructor: { severity },
        },
      }) => (
        <Chip
          avatar={
            <Avatar sx={{ bgcolor: 'transparent' }}>
              <Box
                sx={(theme) => ({
                  height: '80%',
                  bgcolor: severity === 'critical' ? theme.palette.warning.light : theme.palette.caution.light,
                  width: '80%',
                  borderRadius: '50%',
                })}
              />
            </Avatar>
          }
          label={t(severity)}
          size="small"
          color={severity === 'critical' ? 'warning' : 'caution'}
          variant="filled"
        />
      ),
    })
    .addColumn({
      title: t('reportingDate'),
      field: 'createdAt',
      sorting: true,
      defaultSort: 'desc',
      exportTransformer: ({ createdAt }) => createdAt.toLocaleString(),
      render: renderAlarmReportDate,
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      exportTransformer: ({ alarm: { _tag } }) => getAlarmLabel(_tag),
      render: renderAlarmName,
    })
    .addColumn({
      title: t('parameters'),
      field: 'params',
      render: ({ alarm }) => renderAlarmParameters(alarm, t),
    })
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('status')} tooltipText={t('statusTooltip')} />,
      exportTransformer: () => t('active'),
      field: 'dismissed',
      render: () => <BlipChip label={t('active')} size="small" color="error" variant="outlined" />,
    })
    .addColumn({
      title: t('actions'),
      field: 'actions',
      render: ({ id }) => (
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => typeof handleDetail === 'function' && handleDetail(id)}
          >
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    });

  return builder.columns;
};

const buildFleetOverviewAlarmsColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  handleNavigateDevice: (deviceId: string) => void,
  handleDetail?: (id: FleetOverview.Alarm['id']) => void
): Column<FleetOverview.Alarm>[] => {
  const builder = columnsBuilder<FleetOverview.Alarm>();
  builder
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('severity')} tooltipText={t('severityTooltip')} />,
      field: 'severity',
      exportTransformer: ({
        alarm: {
          constructor: { severity },
        },
      }) => t(severity),
      customExport: ({
        alarm: {
          constructor: { severity },
        },
      }) => t(severity),
      customFilterAndSearch: (
        filter: string,
        {
          alarm: {
            constructor: { severity },
          },
        }
      ) => severity.includes(filter),
      render: ({
        alarm: {
          constructor: { severity },
        },
      }) => (
        <Chip
          avatar={
            <Avatar sx={{ bgcolor: 'transparent' }}>
              <Box
                sx={(theme) => ({
                  height: '80%',
                  bgcolor: severity === SEVERITY.CRITICAL ? theme.palette.warning.dark : theme.palette.caution.dark,
                  width: '80%',
                  borderRadius: '50%',
                })}
              />
            </Avatar>
          }
          label={t(severity)}
          size="small"
          variant="filled"
          color={severity === SEVERITY.CRITICAL ? 'warning' : 'caution'}
        />
      ),
    })
    .addColumn({
      title: t('reportingDate'),
      customFilterAndSearch: (filter: string, { time }) => defaultSearchByDate(filter, dayjs(time)),
      field: 'createdAt',
      exportTransformer: ({ createdAt }) => createdAt.toLocaleString(),
      render: renderAlarmReportDate,
    })
    .addColumn({
      title: t('name'),
      field: 'name',
      exportTransformer: ({ alarm: { _tag } }) => getAlarmLabel(_tag),
      render: renderAlarmName,
    })
    .addColumn({
      title: t('device'),
      field: 'deviceId',
      exportTransformer: ({ deviceId }) => deviceId,
      render: ({ deviceId, deviceName }) => (
        <RelatedEntityLabel
          text={deviceName || deviceId || (t('device') as string)}
          onEntityClick={() => handleNavigateDevice(deviceId)}
        />
      ),
    })
    .addColumn({
      title: <TypographyWithTooltip anchorText={t('status')} tooltipText={t('statusTooltip')} />,
      exportTransformer: () => t('active'),
      field: 'dismissed',
      render: () => <BlipChip label={t('active')} size="small" color="error" variant="outlined" />,
    })
    .addColumn({
      title: t('parameters'),
      field: 'params',
      render: ({ alarm }) => renderAlarmParameters(alarm, t),
    })
    .addColumn({
      title: t('actions'),
      render: ({ id }) => (
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => typeof handleDetail === 'function' && handleDetail(id)}
          >
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    });

  return builder.columns;
};
