import { Avatar, Box, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IssueDetails } from './types';
import { Match } from 'effect';

export default function IssueSeverity({ data }: { data: IssueDetails.Data }) {
  const { t } = useTranslation();
  const severity = Match.value(data).pipe(
    Match.when({ _tag: 'FleetOverviewAlarm' }, ({ alarm }) => alarm.constructor.severity),
    Match.when({ _tag: 'FleetAlarms' }, ({ alarm }) => alarm.constructor.severity),
    Match.when({ _tag: 'FleetDevice' }, ({ alarm }) => alarm.constructor.severity),
    Match.when({ _tag: 'FleetOverviewError' }, () => undefined),
    Match.when({ _tag: 'FleetError' }, () => undefined),
    Match.exhaustive
  );

  return (
    <Box>
      {!severity && (
        <Chip
          avatar={
            <Avatar sx={{ bgcolor: 'transparent' }}>
              <Box
                sx={(theme) => ({
                  height: '80%',
                  bgcolor: theme.palette.grey[600],
                  width: '80%',
                  borderRadius: '50%',
                })}
              />
            </Avatar>
          }
          label={t('unknown')}
          size="small"
          color="default"
          variant="filled"
        />
      )}
      {severity && (
        <Chip
          avatar={
            <Avatar sx={{ bgcolor: 'transparent' }}>
              <Box
                sx={(theme) => ({
                  height: '80%',
                  bgcolor: severity === 'critical' ? theme.palette.error.light : theme.palette.warning.light,
                  width: '80%',
                  borderRadius: '50%',
                })}
              />
            </Avatar>
          }
          label={t(severity)}
          size="small"
          color={severity === 'critical' ? 'error' : 'warning'}
          variant="filled"
        />
      )}
    </Box>
  );
}
