import { useCallback, useMemo, useState } from 'react';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import { useTranslation } from 'react-i18next';
import { Alarm } from '@culligan-iot/domain/culligan/device/history';

export const useAlarmDialog = () => {
  const [open, setOpen] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState<Alarm | null>(null);
  const { t } = useTranslation();

  const handleOpenDialog = useCallback((id: string, alarms: Alarm[]) => {
    const alarm = alarms.find((a) => a.id === id);
    setSelectedAlarm(alarm || null);
    setOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setSelectedAlarm(null);
    setOpen(false);
  }, []);

  const body = useMemo(
    () => selectedAlarm && <IssueDetails data={{ _tag: 'FleetAlarms', ...selectedAlarm }} />,
    [selectedAlarm]
  );

  const { dialog } = useCulliganDialog({
    open: open,
    handleClose: handleCloseDialog,
    tabs: [
      {
        id: 'alarm',
        label: t('alarm'),
        body: body,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });

  return { dialog, open, handleOpenDialog };
};
