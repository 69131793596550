import { FieldValues, Path, PathValue } from 'react-hook-form';
import * as Countries from 'i18n-iso-countries';
import { Culligan } from '@culligan-iot/domain';
import { Option } from 'effect';
import { PutInstallationAddress } from '@/redux/api/fleet/devicesApiSlice';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

type AddressOption = google.maps.GeocoderResult;

export type Props<FormSchema extends FieldValues, OptionSchema> = {
  name: Path<FormSchema>;
  options: Array<OptionSchema>;
  handleChange: (option: OptionSchema | null) => PathValue<FormSchema, Path<FormSchema>>;
  handleInputChange: (inputValue: string) => Promise<void>;
  getOptionLabel: (option: OptionSchema) => string;
  isOptionEqualToValue: (option: OptionSchema, value: OptionSchema) => boolean;
  isQuerying: boolean;
};

export const Address = {
  is: (a: AddressOption, b: AddressOption) => a.place_id === b.place_id,
  getLabel: (option: AddressOption) => option.formatted_address,
  getComponent: (
    components: google.maps.GeocoderAddressComponent[] | undefined,
    type: string,
    nameType: 'short_name' | 'long_name' = 'long_name'
  ) => Option.fromNullable(components?.find((c) => c.types.includes(type))?.[nameType]),
};

export const convertToAlpha3 = (alpha2Code: string) => Option.fromNullable(Countries.alpha2ToAlpha3(alpha2Code));

export const makeDefaultValues = (
  currentAddress?: {
    installationAddress: Device['installationAddress'];
    providedLocation: Device['providedLocation'];
  } | null
): typeof PutInstallationAddress.Type => ({
  installationAddress: {
    address: currentAddress?.installationAddress?.address ?? '',
    addressExtra: currentAddress?.installationAddress?.addressExtra ?? '',
    zip: currentAddress?.installationAddress?.zip ?? '',
    city: currentAddress?.installationAddress?.city ?? '',
    state: currentAddress?.installationAddress?.state ?? '',
    country: currentAddress?.installationAddress?.country ?? '',
    countryCode: currentAddress?.installationAddress?.countryCode ?? '',
  },
  providedLocation: currentAddress?.providedLocation
    ? {
        lat: currentAddress?.providedLocation?.lat,
        lon: currentAddress?.providedLocation?.lon,
      }
    : null,
});

export const createAddressFromGeocoderResult = (result: AddressOption) => {
  const { address_components: components, geometry, formatted_address } = result;
  const location = geometry?.location;

  const getComponentValue = (type: string, nameType: 'long_name' | 'short_name' = 'long_name') =>
    Address.getComponent(components, type, nameType).pipe(Option.getOrNull);

  const zip = getComponentValue('postal_code');
  const country = getComponentValue('country');
  const state = getComponentValue('administrative_area_level_1');
  const city =
    getComponentValue('locality') ||
    getComponentValue('sublocality') ||
    getComponentValue('administrative_area_level_2');

  const countryShortCode = getComponentValue('country', 'short_name');
  const countryCode = countryShortCode ? convertToAlpha3(countryShortCode).pipe(Option.getOrNull) : null;

  const providedLocation = location
    ? {
        lat: location.lat(),
        lon: location.lng(),
      }
    : null;

  return PutInstallationAddress.make({
    installationAddress: {
      address: formatted_address,
      addressExtra: null,
      zip: zip ?? '',
      city: city ?? '',
      country: country ?? '',
      countryCode,
      state,
    } satisfies Culligan.Device.Class.Base.CommonProps['installationAddress'],
    providedLocation: providedLocation satisfies Culligan.Device.Class.Base.CommonProps['providedLocation'],
  });
};
