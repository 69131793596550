import { DialogContentText } from '@mui/material';
import DialogContentLabel from '../DialogContentLabel';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

export default function IssueIdentity({ data: { id } }: { data: { id: string } }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <DialogContentLabel>{t('alarmId')}:</DialogContentLabel>
      <DialogContentText textAlign="left" variant="caption">
        {id}
      </DialogContentText>
    </Fragment>
  );
}
