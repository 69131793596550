import { Culligan } from '@culligan-iot/domain';
import { getRemainingPercentage } from '@culligan-iot/domain/culligan/device/class/slotted-consumable';
import { Column } from '@material-table/core';
import { Box, Button, Chip, Typography, alpha } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import TagIcon from '@mui/icons-material/Tag';
import { getColorsFromPercentage } from '@/shared/utils';
import { CONSUMABLE_CATEGORIES, consumableCategoryBySubCategory } from '@/shared/constants';
import { deviceLabelByModel } from '@/shared/domain/device';
import { formatNumber } from '@/shared/domain/numbers';

export const useConsumablesDeviceTableColumns = (
  selectedTab: typeof CONSUMABLE_CATEGORIES.Type
): Array<Column<Culligan.Device.Class.Device>> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMemo(
    (): Array<Column<Culligan.Device.Class.Device>> => [
      {
        title: t('modelTypeId'),
        field: 'id',
        cellStyle: { textAlign: 'left' },
        customExport: ({ id }) => id,
        exportTransformer: ({ id }) => id,
        render: ({ id, vendor, model }) => (
          <Box>
            <Typography variant="subtitle1">
              {deviceLabelByModel[model]} {'//'} {vendor.constructor.label}
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Chip icon={<TagIcon />} label={model} size="small" />
              <Typography variant="caption">{id}</Typography>
            </Box>
          </Box>
        ),
      },
      /*
       * Removed until further notice.
       * {
       *   title: t('location'),
       *   field: 'location',
       *   exportTransformer: (device) => `${device.region?.code} - ${device.region?.name}`,
       *   render: (device) => (
       *     <Typography variant="subtitle1">
       *       {device.region?.code && device.region?.name ? `${device.region.code} - ${device.region.name}` : 'N/A'}
       *     </Typography>
       *   ),
       *   sorting: false,
       * },
       */
      {
        title: t('levels'),
        field: 'consumables',
        customExport: ({ consumables }) => consumables[0],
        exportTransformer: ({ consumables }) =>
          consumables
            .filter(
              (consumable) => consumable && consumableCategoryBySubCategory[consumable.subset._tag] === selectedTab
            )
            .map((consumable) =>
              consumable
                ? `${consumable.subset.id} ${formatNumber(getRemainingPercentage(consumable), 'Percentage')}\n`
                : ''
            )
            .toString()
            .replaceAll(',', ''),
        render: (device) => (
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={1}>
            {device.consumables.map((consumable, index) => {
              if (consumable) {
                const remainingPercentage = getRemainingPercentage(consumable);
                const bgColor = getColorsFromPercentage(remainingPercentage * 100);
                const transparentBgColor = alpha(bgColor, 0.2);

                return (
                  consumableCategoryBySubCategory[consumable.subset._tag] === selectedTab && (
                    <Box
                      key={`consumableSubset-${index}-${device.id}`}
                      sx={{ borderRadius: 1, position: 'relative', width: '100%' }}
                      bgcolor={transparentBgColor}
                    >
                      <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="caption" component="span">
                          {consumable.subset.name}
                        </Typography>
                        <Typography component="span">{formatNumber(remainingPercentage, 'Percentage')}</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          position: 'absolute',
                          overflow: 'hidden',
                          height: '100%',
                          top: 0,
                          left: 0,
                          width: '100%',
                          backgroundColor: bgColor,
                          clipPath: `xywh(0 0 ${remainingPercentage * 100}% 100%)`,
                          padding: 1,
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ color: (theme) => theme.palette.getContrastText(bgColor) }}
                          component="span"
                        >
                          {consumable.subset.name}
                        </Typography>
                        <Typography sx={{ color: (theme) => theme.palette.getContrastText(bgColor) }} component="span">
                          {formatNumber(remainingPercentage, 'Percentage')}
                        </Typography>
                      </Box>
                    </Box>
                  )
                );
              } else {
                return null;
              }
            })}
          </Box>
        ),
        sorting: false,
      },
      {
        title: t('actions'),
        field: 'actions',
        width: '5%',
        sorting: false,
        export: false,
        render: ({ id }) => (
          <Button
            key={`buttonDevicesTable${id}`}
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/fleet/devices/${id}#consumables`)}
          >
            {t('view')}
          </Button>
        ),
      },
    ],
    [navigate, t, selectedTab]
  );
};
