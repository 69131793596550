import React, { useCallback, useState, Fragment } from 'react';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle } from '@/components/Shared/Card/Area/Text';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { isEmptyOrUndefinedObject, isValidNumber } from '@/shared/utils';
import { useTranslation } from 'react-i18next';
import { selectRoles } from '@/redux/slices/authSlice';
import { AppState } from '@/redux/store';
import { ROLES } from '@/shared/constants';
import { useSelector } from 'react-redux';
import { AddressForm } from './InstallationAddress/InstallationAddressForm';
import CulliganDialog from '@/components/Shared/CulliganDialog';

interface AddressProps {
  stringAddress: string;
  device: Device;
}
function Address({ stringAddress, device }: AddressProps) {
  const { t } = useTranslation();
  const [isEditAddressFormOpen, setIsEditAddressFormOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const isAdmin = useSelector((state: AppState) => selectRoles(state)).some(
    (role) => role === ROLES.SYS_ADMIN || role === ROLES.ADMIN
  );

  const handleCloseDialog = () => {
    setIsEditAddressFormOpen(false);
  };

  const _Address = useCallback(() => {
    switch (true) {
      case !!stringAddress:
        return (
          <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 2 }}>
            {Object.entries(device?.installationAddress || {}).map(([key, value]) => (
              <Box key={key}>
                <Typography variant="body2" color="text.secondary">
                  {t(key)}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {value || t('notAvailable')}
                </Typography>
              </Box>
            ))}
            {isValidNumber(device.providedLocation?.lat) && isValidNumber(device.providedLocation?.lon) ? (
              <Box display="flex" gap={1}>
                <CopyChip
                  valueToCopy={`lat: ${device.providedLocation.lat}, lon: ${device.providedLocation.lon}`}
                  icon={<LocationOnIcon />}
                  label={`${t('latitude')} ${device.providedLocation.lat}`}
                />
                <CopyChip
                  valueToCopy={`lat: ${device.providedLocation.lat}, lon: ${device.providedLocation.lon}`}
                  icon={<LocationOnIcon />}
                  label={`${t('longitude')} ${device.providedLocation.lon}`}
                />
              </Box>
            ) : (
              <Fragment />
            )}
          </Box>
        );

      case isValidNumber(device.providedLocation?.lat) && isValidNumber(device.providedLocation?.lon):
        return (
          <Box display="flex" gap={1}>
            <CopyChip
              valueToCopy={`lat: ${device.providedLocation.lat}, lon: ${device.providedLocation.lon}`}
              icon={<LocationOnIcon />}
              label={`${t('latitude')} ${device.providedLocation.lat}`}
            />
            <CopyChip
              valueToCopy={`lat: ${device.providedLocation.lat}, lon: ${device.providedLocation.lon}`}
              icon={<LocationOnIcon />}
              label={`${t('longitude')} ${device.providedLocation.lon}`}
            />
          </Box>
        );

      case device.detectedLocation && !isEmptyOrUndefinedObject(device.detectedLocation):
        return (
          <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1 }}>
            <CopyChip
              valueToCopy={`lat: ${device.detectedLocation.lat}, lon: ${device.detectedLocation.lon}`}
              icon={<LocationOnIcon />}
              label={`${t('latitude')} ${device.detectedLocation.lat}`}
            />
            <CopyChip
              valueToCopy={`lat: ${device.detectedLocation.lat}, lon: ${device.detectedLocation.lon}`}
              icon={<LocationOnIcon />}
              label={`${t('longitude')} ${device.detectedLocation.lon}`}
            />
            <Chip
              size="small"
              icon={<ShareLocationIcon />}
              label={`${t('accuracy')} ${device?.detectedLocation?.accuracy} ${t('meters')}`}
            />
            <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>
              {t('detectedLocation')}
            </Typography>
          </Box>
        );

      default:
        return (
          <Typography variant="body1" fontWeight={400}>
            {t('addressNotAvailable')}
          </Typography>
        );
    }
  }, [
    stringAddress,
    device?.installationAddress,
    device.detectedLocation,
    device.providedLocation?.lat,
    device.providedLocation?.lon,
    t,
  ]);

  const dialogTabs = [
    {
      id: 'address-edit-tab',
      label: t('deviceAddress.editAddress'),
      body: (
        <AddressForm
          closeModal={handleCloseDialog}
          serialNumber={device.id}
          currentAddress={
            device.installationAddress || device.providedLocation
              ? {
                  installationAddress: device.installationAddress,
                  providedLocation: device.providedLocation,
                }
              : null
          }
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Area onClick={() => setIsEditAddressFormOpen(true)} sx={{ borderRadius: 1 }}>
        <AreaHeader
          sx={{
            ...(!stringAddress ? { mb: 2 } : {}),
          }}
        >
          <TypographyWithTooltip
            anchorText={t('location')}
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            tooltipText={t('locationTooltip')}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            {stringAddress && (
              <IconButton size="small">
                <ContentCopyIcon />
              </IconButton>
            )}
            <RenderIf condition={isAdmin}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditAddressFormOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </RenderIf>
          </Box>
        </AreaHeader>
        <AreaBody sx={{ width: '100%' }}>{_Address()}</AreaBody>
      </Area>
      <CulliganDialog
        styles={{ bodyContainer: { p: 2 } }}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        id="edit-address-dialog"
        open={isEditAddressFormOpen}
        handleClose={handleCloseDialog}
        tabs={dialogTabs}
        maxWidth="sm"
        fullWidth
      />
    </Fragment>
  );
}

export default React.memo(Address);
