/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-redeclare */

import { Fragment } from 'react';

import type { IssueDetails } from './types';
import IssueDevice from './IssueDevice';
import IssueDismiss from './IssueDismiss';
import IssueSeverity from './IssueSeverity';
import IssueLabel from './IssueLabel';
import IssueDescription from './IssueDescription';
import IssueDate from './IssueDate';
import IssueIdentity from './IssueIdentity';

import RenderIf from '../../RenderIf/RenderIf';

import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';

export default function IssueDetails({ data }: { data: IssueDetails.Data }) {
  const hasDeviceInfoData = data._tag !== 'FleetDevice';
  const hasDimissableData = data._tag === 'FleetAlarms' || data._tag === 'FleetError';
  const hasSeverityData =
    data._tag === 'FleetAlarms' || data._tag === 'FleetOverviewAlarm' || data._tag === 'FleetDevice';
  const hasDescriptionData =
    data._tag === 'FleetAlarms' || data._tag === 'FleetOverviewAlarm' || data._tag === 'FleetDevice';
  const isDeviceDetail = data._tag === 'FleetDevice';
  const styles = isDeviceDetail ? { ...IssueContentStyles, pt: 2 } : IssueContentStyles;

  return (
    <Fragment>
      {hasDeviceInfoData && <IssueDevice data={data} />}
      <DialogContent sx={styles}>
        <RenderIf condition={hasDimissableData || hasSeverityData}>
          <Box display="flex" gap={1} py={1}>
            <IssueDismiss data={data} />
            <IssueSeverity data={data} />
          </Box>
        </RenderIf>
        <IssueLabel data={data} />
        <IssueDate data={data} />
        {hasDescriptionData && <IssueDescription data={data} />}
        <IssueIdentity data={data} />
      </DialogContent>
    </Fragment>
  );
}

const IssueContentStyles = { p: '2rem', py: '0', pb: '1rem', display: 'flex', flexDirection: 'column', gap: 2 };
