import { styled } from '@mui/material';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { ComponentType, useEffect, useState } from 'react';
import { CustomPickerDayProps, DateRange } from './typings';

function isInRange(value: Dayjs, range: Partial<DateRange<Dayjs>> | null) {
  if (!range) {
    return false;
  }

  if (range?.start?.isSame(value, 'day')) {
    return true;
  }

  if (!range.start || !range.end) {
    return false;
  }

  if (value.isBetween(range.start.subtract(1, 'day'), range.end)) {
    return true;
  }

  return false;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'range',
})<CustomPickerDayProps>(({ theme, isSelected, day, range }) => ({
  '&.MuiPickersDay-today': {
    border: 0,
    outline: `2px solid ${theme.palette.primary.light}`,
    outlineOffset: -2,
  },
  borderRadius: '50%',
  height: 40,
  '&:active': {
    border: 0,
    outline: 0,
  },
  '&:focus': {
    border: 0,
    outline: 0,
  },
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 0,
    '&.MuiPickersDay-today': {
      border: 0,
      outline: 0,
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(range?.start?.isSame(day, 'day') && {
    borderRadius: '50%',
  }),
  ...(range?.end &&
    range?.start?.isSame(day, 'day') && {
      borderRadius: '50%',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }),
  ...(range?.end?.isSame(day, 'day') && {
    borderRadius: '50%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }),
})) as React.ComponentType<CustomPickerDayProps>;

function Day(
  props: PickersDayProps<Dayjs> & {
    range: {
      start?: Dayjs;
      end?: Dayjs;
    };
  }
) {
  const { day, range, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInRange(day, range)}
      range={range}
    />
  );
}

export default function DateRangePicker(props: {
  range: DateRange<Dayjs> | null;
  isCustomRange: boolean;
  isChoosingCustomRangeHandler: (isChoosingCustomRange: boolean) => void;
  updateCustomRangeHandler: (range: DateRange<Dayjs>) => void;
}) {
  const [range, setRange] = useState<Partial<DateRange<Dayjs>> | null>(props.range);
  const minDate = range?.start?.subtract(730, 'day').startOf('day');
  const maxDate = range?.start?.add(730, 'day').endOf('day');

  useEffect(() => {
    if (!props.range || (props.range?.start && props.range?.end)) {
      setRange(props.range);
      return;
    }

    if (range?.start && !range?.end) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.range]);

  const handleChange = (newValue: Dayjs | null) => {
    props.isChoosingCustomRangeHandler(true);

    if (!newValue) {
      setRange(null);
      return;
    }

    if (range?.start && range?.end) {
      setRange({
        start: newValue,
        end: undefined,
      });

      return;
    }

    if (!range?.start) {
      setRange({
        start: newValue,
      });

      return;
    }

    if (range?.start && range.start.isBefore(newValue)) {
      setRange({
        start: range.start,
        end: newValue,
      });

      return;
    }

    if (range?.start && range.start.isAfter(newValue)) {
      setRange({
        start: newValue,
        end: range.start,
      });

      return;
    }
  };

  const handleYearChange = () => {
    if (props.isCustomRange === false) {
      props.isChoosingCustomRangeHandler(false);
    }

    if (range?.start && !range?.end) {
      setRange({
        start: range?.start,
        end: undefined,
      });

      return;
    }

    if (range?.start && range?.end) {
      setRange({
        start: range?.start,
        end: range?.end,
      });

      return;
    }
  };

  useEffect(() => {
    if (range?.start && range?.end) {
      props.updateCustomRangeHandler(range as DateRange<Dayjs>);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  return (
    <DateCalendar
      value={range?.end || range?.start || dayjs()}
      onChange={handleChange}
      showDaysOutsideCurrentMonth
      onYearChange={handleYearChange}
      minDate={minDate}
      maxDate={maxDate}
      disableFuture
      slots={{ day: Day as ComponentType<PickersDayProps<dayjs.Dayjs>> }}
      slotProps={{
        day: () =>
          ({
            range: range,
          } as any),
      }}
    />
  );
}
