import { ApiResult } from '@typings';
import {
  DeviceIdentityRes,
  DeviceIdentityWithRelatedRes,
} from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentitiesPanel/typings';
import apiSlice, { API_VERSION } from '../apiSlice';

const URL_PREFIX = `${API_VERSION.v2}/system/deviceIdentities`;

export const DeviceIdentitiesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['DeviceIdentities'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDeviceIdentities: builder.query<ApiResult<{ items: DeviceIdentityRes[]; appEnv: string }>, void>({
        query: () => URL_PREFIX + '/list',
        providesTags: ['DeviceIdentities'],
      }),
      getDeviceIdentity: builder.query<ApiResult<DeviceIdentityRes>, string>({
        query: (id: string) => ({
          url: URL_PREFIX,
          params: { id },
        }),
        providesTags: ['DeviceIdentities'],
      }),
      getDeviceIdentityWithRelated: builder.query<ApiResult<DeviceIdentityWithRelatedRes>, string>({
        query: (id: string) => ({
          url: URL_PREFIX + '/related',
          params: { id },
        }),
        providesTags: ['DeviceIdentities'],
      }),
    }),
    overrideExisting: 'throw',
  });

export const { useGetDeviceIdentityWithRelatedQuery, useGetDeviceIdentityQuery, useGetDeviceIdentitiesQuery } =
  DeviceIdentitiesApiSlice;
