import { STATUS, Tag, TAGS } from '@/redux/slices/operationSlice';
import BaseNotification, { StatusNotification } from '../../BaseNotification';
import BaseOperationAvatar from '../../../Operation/BaseOperationAvatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getDefaultStatusIcon } from '../utils';
import BaseOperationIconButton from '../../../Operation/BaseOperationIconButton';
import DefaultNotificationRightAction from '../../DefaultNotificationRightAction';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BaseOperationIcon from '../../../Operation/BaseOperationIcon';
import { useTranslation } from 'react-i18next';
import useDefaultErrorCallback from '../../hooks/useDefaultErrorCallback';
import { useNavigate } from 'react-router';

import { useNotificationHeight } from '../../hooks/useNotificationHeight';
import { Operations } from '../../../Operation/types';
import { NotificationVirtualizationProps } from '../../types';
import React, { useCallback, useMemo } from 'react';
import { getPath } from '@/shared/utils';
import QNumberMessage from './QNumberMessage';
type QNumberTag = Extract<Tag, typeof TAGS.QNUMBER_CREATE | typeof TAGS.QNUMBER_UPDATE | typeof TAGS.QNUMBER_DELETE>;
type QNumberNotificationProps = Operations.Props & { tag: QNumberTag } & NotificationVirtualizationProps;
export function QNumber(props: QNumberNotificationProps) {
  const { setNotificationHeight, status, read, uniqueId, entity, subject, tag } = props;
  const notificationRef = useNotificationHeight({ id: uniqueId, setNotificationHeight });
  const { t } = useTranslation();
  const defaultErrorCallback = useDefaultErrorCallback({ uniqueId });
  const navigate = useNavigate();
  const path = getPath('FLEET_DEVICE', { deviceId: entity });
  const handleClick = useCallback(() => {
    navigate(path, {
      state: {
        searchText: subject,
      },
    });
  }, [navigate, path, subject]);
  const message = useMemo(() => {
    const getOpening = () => {
      switch (tag) {
        case TAGS.QNUMBER_CREATE:
          if (status === STATUS.ERROR) {
            return t('qNumber.notification.createdError');
          }
          return t('qNumber.notification.created');
        case TAGS.QNUMBER_UPDATE:
          if (status === STATUS.ERROR) {
            return t('qNumber.notification.updatedError');
          }
          return t('qNumber.notification.updated');
        case TAGS.QNUMBER_DELETE:
          if (status === STATUS.ERROR) {
            return t('qNumber.notification.deletedError');
          }
          return t('qNumber.notification.deleted');
        default:
          return '';
      }
    };
    return <QNumberMessage read={read} status={status} opening={getOpening()} entity={t(entity)} subject={subject} />;
  }, [read, status, tag, t, entity, subject]);
  const mainIconButton = useMemo(
    () => (
      <BaseOperationIconButton
        status={status}
        read={read}
        icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
      />
    ),
    [status, read]
  );
  const rightAction = useMemo(
    () => <DefaultNotificationRightAction status={status} read={read} uniqueId={uniqueId} />,
    [status, read, uniqueId]
  );
  return (
    <StatusNotification
      {...props}
      ref={notificationRef}
      baseComponent={{ Component: BaseNotification }}
      avatar={
        <BaseOperationAvatar
          status={status}
          tagIcon={<BaseOperationIcon Icon={LocationOnIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          message: message,
          onClick: defaultErrorCallback,
          hoverIconButtons: {
            main: mainIconButton,
            rightAction: rightAction,
          },
        },
        [STATUS.SUCCESS]: {
          message: message,
          onClick: handleClick,
          hoverIconButtons: {
            main: mainIconButton,
            rightAction: rightAction,
          },
        },
      }}
    />
  );
}
export default React.memo(QNumber);
