import { Operation, STATUS } from '@/redux/slices/operationSlice';
import { useTranslation } from 'react-i18next';
import BaseOperationAvatar from '../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../Operation/BaseOperationIcon';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BaseOperationIconButton from '../../Operation/BaseOperationIconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CommonSnackbarProps } from '../typings';
import { StatusNotification } from '../../Notification/BaseNotification';
import BaseSnackbar from '../BaseSnackbar';
import StatusSnackbarActions from '../StatusSnackbarActions';
import useDefaultErrorCallback from '../../Notification/hooks/useDefaultErrorCallback';
import { getDefaultStatusIcon } from '../../Notification/ByTag/utils';
import ContactInfoMessage from '@/components/Core/Notification/ByTag/ContactInfo/Message';

export default function ContactInfoVariant({
  operation,
  dismissAction,
}: CommonSnackbarProps & { operation: Operation }) {
  const { t } = useTranslation();
  const defaultErrorCallback = useDefaultErrorCallback(operation);

  return (
    <StatusNotification
      {...operation}
      baseComponent={{
        Component: BaseSnackbar,
        extra: { snackbarActions: <StatusSnackbarActions operation={operation} dismissAction={dismissAction} /> },
      }}
      avatar={
        <BaseOperationAvatar
          status={operation.status}
          tagIcon={<BaseOperationIcon Icon={LocationOnIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(operation.status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          message: (
            <ContactInfoMessage
              read={operation.read}
              status={operation.status}
              opening={operation.status === STATUS.ERROR ? t('contactInfoUpdatedError') : t('contactInfoUpdated')}
              entity={t(operation.entity)}
              subject={operation.subject}
            />
          ),
          onClick: defaultErrorCallback,
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={operation.status}
                read={operation.read}
                icon={<BaseOperationIcon Icon={VisibilityIcon} color="inherit" />}
              />
            ),
          },
        },
        [STATUS.SUCCESS]: {
          message: (
            <ContactInfoMessage
              read={operation.read}
              status={operation.status}
              opening={operation.status === STATUS.ERROR ? t('contactInfoUpdatedError') : t('contactInfoUpdated')}
              entity={t(operation.entity)}
              subject={operation.subject}
            />
          ),
          onClick: () => '',
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={operation.status}
                read={operation.read}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
          },
        },
      }}
    />
  );
}
