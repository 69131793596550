import { COMMANDS, CONNECTIVITIES, CONSUMABLE_KIND, SEVERITY } from '@/shared/constants';
import { LoyaltyProgram } from '@culligan-iot/domain/culligan/user/loyalty';
import { FleetError } from '../../AlarmsAndErrors/typings';
import { DeviceTelemetries } from '../DevicePanel/TelemetryPanel/typings';
import { LogLevel, OperatingMode, PowerProfile } from '@culligan-iot/domain/culligan/device/class/base';
import { Record, Schema as S } from 'effect';
import { getTableFilters } from '@/components/Shared/Tables/utils';
import { TableRef } from '@/components/Shared/Tables/typings';
import { isValidNumber } from '@/shared/utils';
import { Device as DeviceListDevice } from '@culligan-iot/domain/culligan/api/device/deviceList';
import { Model } from '@/shared/domain/device_derived_fields';
import { deviceVendorCodes } from '@/shared/domain/device';

export namespace FleetDevices {
  export const FilterKeys = {
    Model: 'model',
    Status: 'status',
    Brand: 'brand',
    Page: 'page',
    Size: 'size',
    Customer: 'customer',
    OrderBy: 'orderBy',
    Direction: 'direction',
    Search: 'search',
  } as const;

  export type Device = DeviceListDevice;
  export type DeviceWithLabel = DeviceListDevice & { label: string };
  export type DeviceWithoutCoordinate = Omit<DeviceListDevice, 'providedLocation'>;

  export type Columns = typeof Columns.Type;
  export const Columns = S.Literal('alarms', 'errors', 'model', 'status', 'swVersion');

  const TableFilters = getTableFilters(Columns);
  const FilterBarFilters = S.Struct({
    [FilterKeys.Model]: S.optional(Model),
    [FilterKeys.Status]: S.optional(S.Literal('connected', 'disconnected')),
    [FilterKeys.Brand]: S.optional(S.Literal(...deviceVendorCodes)),
    [FilterKeys.Customer]: S.optional(S.String),
  });

  const Filters = S.Struct({ ...TableFilters.fields, ...FilterBarFilters.fields });

  export const encode = S.encodeEither(Filters);
  export const decode = S.decodeUnknownEither(Filters);

  export type DecodedFilters = typeof Filters.Type;
  export type EncodedFilters = typeof Filters.Encoded;
  export type FilterKey = keyof DecodedFilters;

  export type Table = TableRef<DeviceListDevice>;
}

type TransformUnderscoredLowercase<S extends string> = S extends `${infer Prefix}_${infer Rest}`
  ? `${Lowercase<Prefix>}_${Capitalize<Lowercase<Rest>>}`
  : Lowercase<S>;

type TransformKey<T extends string> = T extends `${infer Prefix}_${infer Rest}` ? `${Prefix}${Rest}` : T;

// TODO - Replace with an alarm derived from the device definition
export type DeviceAlarm = {
  id: string;
  time: number;
  alarm: {
    id: string;
    name: string;
    description?: string;
    severity?: (typeof SEVERITY)[keyof typeof SEVERITY];
  };
};

// TODO - Replace with an alarm derived from the device definition
export type DeviceError = Omit<
  FleetError,
  'deviceId' | 'deviceName' | 'dismissed' | 'dismissedAt' | 'createdAt' | 'name'
> & { errorName: string };

// TODO - Replace with an alarm derived from the device definition
export type DeviceConsumable = {
  current: number;
  expiresAt?: number;
  installedAt?: number;
  batch?: string;
  index: number;
  consumable: {
    id: string;
    name?: string;
    description?: string;
    kind?: ConsumableKind;
  };
  subset?: {
    id: string;
    name?: string;
    description?: string;
    rangeExhaust?: number;
    rangeTrigger?: number;
    rangeFullCapacity?: number;
    lifespan?: number;
  };
};

//
// =============================================================================================================
// These types could be removed after a careful investigation and after the CO-763 is merged in main
//
type serialNumber = string;

export type LiteralConfigFragment = string;

export type Config = Record<string, LiteralConfigFragment | Record<string, LiteralConfigFragment>>;

export type Device = {
  id: string;
  model: string;
  name?: string;
  config?: Config;
  serialNumber: serialNumber;
  region: Region;
  description?: string;
  connectivity?: (typeof CONNECTIVITIES)[keyof typeof CONNECTIVITIES][];
  image?: string;
  protocolVersion: string;
  operatingMode: OperatingMode;
  powerProfile?: PowerProfile;
  logLevel?: LogLevel;
  supportedOperatingModes?: OperatingMode[];
  supportedPowerProfiles?: PowerProfile[];
  swVersion: string;
  createdAt: string;
  updatedAt: string;
  status: Status;
  alarms: DeviceAlarm[];
  errors: DeviceError[];
  consumables: DeviceConsumable[];
  telemetry: DeviceTelemetries;
  installationAddress?: DeviceInstallationAddress;
  registeredAt: string | Date;
  currentUserRole: string;
  dealerId: string;
  ownerId?: string;
  ownerName?: string;
  lat?: number;
  lon?: number;
  detectedLocation?: {
    lat: number;
    lon: number;
    accuracy: number;
  };
  loyaltyPrograms?: LoyaltyProgram[];
  metaData?: MetaData;
  brandName?: string;
  brandId?: string;
};

export type DeviceWithCoordinates = Omit<Device, 'lat' | 'lon'> & {
  lat: number;
  lon: number;
};

export const isDeviceWithCoordinates = (device: Device | DeviceWithCoordinates): device is DeviceWithCoordinates => {
  return isValidNumber(device.lat) && isValidNumber(device.lon);
};

export interface MetaData {
  deviceQnumber?: string;
  manufacturingSerialNumber?: string;
}

export type ConsumableKind = (typeof CONSUMABLE_KIND)[keyof typeof CONSUMABLE_KIND];
export type Command = (typeof COMMANDS)[keyof typeof COMMANDS];
export type ShoppingDialogDetails = {
  devices: Device[];
  consumableType: ConsumableKind | 'all';
};

export type ConsumableShoppingDialogProps = {
  devices: Device[];
  onClose: Function;
  consumableType: ConsumableKind | 'all';
};

type Status = {
  connection: DeviceStatusConnection;
  errors: number;
  alarms: {
    -readonly [K in keyof typeof SEVERITY as TransformKey<TransformUnderscoredLowercase<K>>]: number;
  };
};

export type DeviceConnectionHistoryItem = {
  id: string;
  online: boolean;
  region: {
    code: string;
    name: string;
  };
  status: string;
  createdAt: string;
};

export type UpdateDeviceLoyaltyProgram = {
  index: number;
};

type Region = {
  code: string;
  name: string;
  address?: string;
};

type DeviceInstallationAddress = {
  address: string;
  zip: string;
  city: string;
  state: string;
  country: string;
};

export type AlarmsOverview = {
  -readonly [K in (typeof SEVERITY)[keyof typeof SEVERITY]]: number;
};

type DeviceStatusConnection = {
  online: boolean;
  lastUpdate: string | Date;
};

export type GraphData = { [key: string]: any } & Required<Pick<{ [key: string]: any }, 'data' | 'label'>>;

//
// ==========================================================================================================
//
