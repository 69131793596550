/**
 * Temporary Feature Visibility Control
 *
 * JIRA: CO-863 (https://culligan.atlassian.net/jira/software/c/projects/CO/boards/117?selectedIssue=CO-863)
 *
 * Some features have been temporarily commented out in this component as they are
 * currently non-functional. This is a temporary measure until the features are fully
 * implemented and tested.
 *
 */
import { StoreFilterConfig } from '@/components/Shared/Filters/typings';
import { useMemo, useCallback, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryStringFilters } from '../../../../hooks/useQueryStringFilters';
import { DateRangeDefaultValue, toOneBasedIndex, toZeroBasedIndex } from '@/shared/utils';
import dayjs from 'dayjs';
import { SEVERITY, ISSUE_STATUS } from '@/shared/constants';
import { Schema as S, Either, Option, Record } from 'effect';
import { Query } from '@material-table/core';
import { Severity } from '@culligan-iot/domain/culligan/device/capability/alarm';
import { Alarm } from '@culligan-iot/domain/culligan/device/history';
import { identities } from '@culligan-iot/domain/culligan/device/class/index';
import { getTableFilters } from '@/components/Shared/Tables/utils';
import { User } from '@culligan-iot/domain/culligan/index';
import { deviceVendorCodes } from '@/shared/domain/device';
import { Model } from '@/shared/domain/device_derived_fields';

const FilterStatus = S.Enums({
  Active: 'active',
  Dismissed: 'dismissed',
});

const dateRange = S.Struct({
  start: S.Number,
  end: S.Number,
});

const FILTER_KEYS = {
  START: 'start',
  VENDOR: 'vendor',
  MODEL: 'model',
  END: 'end',
  STATUS: 'status',
  CUSTOMER: 'customer',
  SEVERITY: 'severity',
  PAGE: 'page',
  PAGESIZE: 'size',
  ORDERBY: 'orderBy',
  DIRECTION: 'direction',
} as const;

const AlarmsFilters = S.Struct({
  [FILTER_KEYS.START]: S.optional(S.NumberFromString),
  [FILTER_KEYS.END]: S.optional(S.NumberFromString),
  [FILTER_KEYS.SEVERITY]: S.optional(Severity),
  [FILTER_KEYS.STATUS]: S.optional(FilterStatus),
  [FILTER_KEYS.CUSTOMER]: S.optional(User.Id),
  [FILTER_KEYS.VENDOR]: S.optional(S.Literal(...deviceVendorCodes)),
  [FILTER_KEYS.MODEL]: S.optional(Model),
});

const AlarmsTableColumns = S.keyof(Alarm);
const AlarmsTableFilters = getTableFilters(AlarmsTableColumns);

const Filters = S.Struct({
  ...AlarmsFilters.fields,
  ...AlarmsTableFilters.fields,
});

export type AlarmsFiltersDecoded = typeof Filters.Type;
export type AlarmsFiltersEncoded = typeof Filters.Encoded;

export type AlarmsTableFields = Exclude<AlarmsFiltersDecoded['orderBy'], undefined>;

const encode = S.encodeEither(Filters);
const decode = S.decodeUnknownEither(Filters);

export const useAlarmFilters = (
  tableRef: MutableRefObject<
    | {
        onQueryChange: (query: Partial<Query<Alarm>>, callback?: () => void) => void;
      }
    | undefined
  >
) => {
  const { t } = useTranslation();
  const dateRangeDefaultValue = useMemo(
    () => new DateRangeDefaultValue(dayjs().subtract(1, 'month').startOf('day'), dayjs().endOf('day')),
    []
  );
  const fallbackDateRangeStart = Number(dateRangeDefaultValue.toUnixAsString().start);
  const fallbackDateRangeEnd = Number(dateRangeDefaultValue.toUnixAsString().end);
  const defaultFilterValues: AlarmsFiltersEncoded = useMemo(
    () => ({
      start: dateRangeDefaultValue.toUnixAsString().start,
      end: dateRangeDefaultValue.toUnixAsString().end,
      page: '1',
      size: '10',
    }),
    [dateRangeDefaultValue]
  );

  const { getQueryFilters, setQueryFilters, upsertQueryFilters, deleteQueryFilters } = useQueryStringFilters<
    AlarmsFiltersDecoded,
    AlarmsFiltersEncoded
  >({
    defaultFilterValues,
    encode,
    decode,
  });

  const {
    start,
    end,
    severity,
    status,
    // customer, vendor,
    model,
  } = getQueryFilters();

  // const deviceVendors = useMemo(() => {
  //   return deviceVendorCodes.map((code) => ({
  //     id: code,
  //     name: deviceVendorsCodesAndNames[code],
  //     code: code,
  //   }));
  // }, []);

  // const maybeVendor = useMemo(() => {
  //   return deviceVendors
  //     .filter((b) => b.id === vendor)
  //     .map((b) => ({ label: b.name, optionId: b.id }))
  //     .at(0);
  // }, [deviceVendors, vendor]);

  const deviceModels = useMemo(() => {
    return Object.entries(identities).map(([id, constructor]) => ({
      id,
      label: constructor.label,
      code: constructor.code,
    }));
  }, []);

  const maybeModel = useMemo(() => {
    return deviceModels
      .filter((m) => m.code === model)
      .map((m) => ({
        label: m.label,
        optionId: m.code,
      }))
      .at(0);
  }, [deviceModels, model]);

  // const [trigger] = fleetApiSlice.endpoints.getFleetCustomer.useLazyQuery();

  // const fetchInitialCustomer = useCallback(async () => {
  //   if (!customer) return;

  //   const { customer: data } = await trigger({ customerId: customer }).unwrap();

  //   return pipe(
  //     data,
  //     Option.fromNullable,
  //     Option.match({
  //       onNone: () => ({ label: '', optionId: '' }),
  //       onSome: (customer) => ({
  //         label: `${customer.firstName} ${customer.lastName}`,
  //         optionId: customer.id,
  //       }),
  //     })
  //   );
  // }, [customer, trigger]);

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      upsertQueryFilters([
        { key: 'page', value: toOneBasedIndex(page) || toOneBasedIndex(0) },
        { key: 'size', value: pageSize },
      ]);
    },
    [upsertQueryFilters]
  );

  const handleOrderChange = useCallback(
    (orderBy: AlarmsTableFields, direction: Exclude<AlarmsFiltersDecoded['direction'], undefined>) => {
      try {
        if (!orderBy || !direction) {
          deleteQueryFilters(['orderBy', 'direction']);
        } else {
          const fieldMappings: Record<string, string> = {
            name: 'alarm',
            severity: 'severity',
          };

          const backendOrderBy = fieldMappings[orderBy as string] || orderBy;
          upsertQueryFilters([
            { key: 'orderBy', value: backendOrderBy },
            { key: 'direction', value: direction },
          ]);
        }
      } catch (error) {
        console.error('[AlarmFilters] Error handling order change:', error);
      }
    },
    [deleteQueryFilters, upsertQueryFilters]
  );

  const filterConfig: StoreFilterConfig[] = useMemo(
    () => [
      {
        id: 'dateRange',
        label: t('dateRange'),
        kind: 'dateRangeWithFixedRanges',
        defaultValue: {
          start: start || fallbackDateRangeStart,
          end: end || fallbackDateRangeEnd,
        },
      },
      // {
      //   id: FILTER_KEYS.VENDOR,
      //   label: t('businessUnit'),
      //   kind: 'autocomplete',
      //   options: deviceVendors.map((vendor) => ({
      //     label: vendor.name,
      //     optionId: vendor.id,
      //   })),
      //   defaultValue: maybeVendor,
      // },
      {
        id: FILTER_KEYS.MODEL,
        label: t('deviceType'),
        kind: 'autocomplete',
        options: deviceModels.map((model) => ({
          label: `${model.label} (${model.code})`,
          optionId: model.code,
        })),
        defaultValue: maybeModel,
      },
      {
        id: FILTER_KEYS.SEVERITY,
        label: t(FILTER_KEYS.SEVERITY),
        kind: 'autocomplete',
        options: [
          {
            label: t(SEVERITY.CRITICAL),
            optionId: SEVERITY.CRITICAL,
          },
          {
            label: t(SEVERITY.NON_CRITICAL),
            optionId: SEVERITY.NON_CRITICAL,
          },
        ],
        ...(severity
          ? {
              defaultValue: {
                label: t(severity),
                optionId: severity,
              },
            }
          : {}),
      },
      {
        id: FILTER_KEYS.STATUS,
        label: t(FILTER_KEYS.STATUS),
        kind: 'autocomplete',
        options: [
          {
            label: t(ISSUE_STATUS.ACTIVE),
            optionId: ISSUE_STATUS.ACTIVE,
          },
          {
            label: t('resolved'),
            optionId: ISSUE_STATUS.DISMISSED,
          },
        ],
        ...(status
          ? {
              defaultValue: {
                label: t(status),
                optionId: status,
              },
            }
          : {}),
      },
      // {
      //   id: 'customer',
      //   label: t('customer'),
      //   kind: 'asyncAutocomplete',
      //   shouldFetch: (query: string) => query.length >= 3 && /[a-zA-Z]/g.test(query),
      //   transformFn: (data: { items: UsersFilterResponse[] }) => {
      //     return data?.items?.map((item) => {
      //       const toSearchForMatches = data?.items.filter((user) => user.id !== item.id);
      //       const hasHomonym =
      //         toSearchForMatches?.length > 0 &&
      //         toSearchForMatches.findIndex(
      //           (user) =>
      //             user?.firstName?.toLowerCase() === item?.firstName?.toLowerCase() &&
      //             user?.lastName?.toLowerCase() === item?.lastName?.toLowerCase()
      //         ) !== -1;
      //       return { ...item, optionId: item?.id, label: `${item?.firstName} ${item?.lastName}`, hasHomonym };
      //     });
      //   },
      //   lazyQueryHook: useLazyGetUsersQuery,
      //   getQueryParam: (query) => ({ query } as UsersFilterRequest),
      //   debounceTime: 400,
      //   getInitialValue: fetchInitialCustomer,
      //   renderOption: (
      //     props,
      //     option: StoreFilterOption & { email?: string | undefined; hasHomonym?: boolean | undefined },
      //     state
      //   ) => {
      //     const matches = match(option.label, state.inputValue, { insideWords: true });
      //     const labelWithQueryHighlight = parse(option.label, matches);

      //     return (
      //       <li {...props} key={option?.optionId}>
      //         <Box alignItems="center">
      //           <Box sx={{ wordWrap: 'break-word' }}>
      //             {labelWithQueryHighlight.map((part, index) => (
      //               <Box
      //                 key={`${index}-${part.text}`}
      //                 component="span"
      //                 sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
      //               >
      //                 {part.text}
      //               </Box>
      //             ))}
      //             {option?.hasHomonym && (
      //               <Typography variant="body2" color="text.secondary">
      //                 {option?.['email']}
      //               </Typography>
      //             )}
      //           </Box>
      //         </Box>
      //       </li>
      //     );
      //   },
      // },
    ],
    [
      t,
      start,
      fallbackDateRangeStart,
      end,
      fallbackDateRangeEnd,
      // deviceVendors,
      // maybeVendor,
      deviceModels,
      maybeModel,
      severity,
      status,
      // fetchInitialCustomer,
    ]
  );

  const refreshTable = useCallback(() => {
    tableRef?.current?.onQueryChange({ page: toZeroBasedIndex(1), pageSize: 10 });
  }, [tableRef]);

  const handleFiltersApplied = useCallback(
    (filtersApplied: Map<string, string>) => {
      const getFilterApplied = (key: string) => Option.fromNullable(filtersApplied.get(key));

      const dateRangeFilter = Either.try(() => {
        const dateRange = filtersApplied.get('dateRange');
        if (dateRange != null) {
          return JSON.parse(dateRange);
        }
        return Either.left('No date range present');
      }).pipe(
        Either.getOrElse(() => []),
        S.decodeUnknownEither(dateRange),
        Either.match({
          onLeft: () => [],
          onRight: (dateAsJSON) => [
            { key: FILTER_KEYS.START, value: dateAsJSON.start },
            { key: FILTER_KEYS.END, value: dateAsJSON.end },
          ],
        })
      );

      const severityFilter = getFilterApplied(FILTER_KEYS.SEVERITY).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(Severity),
        Either.match({
          onLeft: () => [],
          onRight: (right) => [
            {
              key: FILTER_KEYS.SEVERITY,
              value: right,
            },
          ],
        })
      );

      const vendorFilter = getFilterApplied(FILTER_KEYS.VENDOR).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.Literal(...deviceVendorCodes)),
        Either.match({
          onLeft: () => [],
          onRight: (right) => [
            {
              key: FILTER_KEYS.VENDOR,
              value: right,
            },
          ],
        })
      );

      const modelFilter = getFilterApplied(FILTER_KEYS.MODEL).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(Model),
        Either.match({
          onLeft: () => [],
          onRight: (right) => [
            {
              key: FILTER_KEYS.MODEL,
              value: right,
            },
          ],
        })
      );

      const statusFilter = getFilterApplied(FILTER_KEYS.STATUS).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(FilterStatus),
        Either.match({
          onLeft: () => [],
          onRight: (right) => [
            {
              key: FILTER_KEYS.STATUS,
              value: right,
            },
          ],
        })
      );

      const customerFilter = getFilterApplied(FILTER_KEYS.CUSTOMER).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(User.Id),
        Either.match({
          onLeft: () => [],
          onRight: (right) => [
            {
              key: FILTER_KEYS.CUSTOMER,
              value: right,
            },
          ],
        })
      );

      const tableFilters = [
        { key: FILTER_KEYS.PAGE, value: 1 },
        { key: FILTER_KEYS.PAGESIZE, value: 10 },
      ];

      const payload = [
        ...dateRangeFilter,
        ...severityFilter,
        ...vendorFilter,
        ...modelFilter,
        ...statusFilter,
        ...tableFilters,
        ...customerFilter,
      ];

      if (filtersApplied.get(FILTER_KEYS.CUSTOMER)) {
        payload.push({
          key: FILTER_KEYS.CUSTOMER as any, // to debug.
          value: filtersApplied.get('customer') || '',
        });
      }

      setQueryFilters(payload);
      refreshTable();
    },
    [refreshTable, setQueryFilters]
  );

  const handleFiltersCleared = useCallback(() => {
    const toClear = new Set(Object.values(FILTER_KEYS));
    const toKeep = new Set([
      FILTER_KEYS.START,
      FILTER_KEYS.END,
      FILTER_KEYS.ORDERBY,
      FILTER_KEYS.DIRECTION,
      FILTER_KEYS.MODEL,
    ]);
    const asList = Array.from(toClear.difference(toKeep));
    deleteQueryFilters(asList);

    const defaultFilters = [
      {
        key: FILTER_KEYS.START,
        value: fallbackDateRangeStart,
      },
      {
        key: FILTER_KEYS.END,
        value: fallbackDateRangeEnd,
      },
      {
        key: FILTER_KEYS.PAGE,
        value: 1,
      },
      {
        key: FILTER_KEYS.PAGESIZE,
        value: 10,
      },
    ];

    setQueryFilters(defaultFilters);
    refreshTable();
  }, [deleteQueryFilters, fallbackDateRangeEnd, fallbackDateRangeStart, refreshTable, setQueryFilters]);

  return {
    filterConfig,
    handleFiltersApplied,
    handleFiltersCleared,
    getQueryFilters,
    handlePageChange,
    handleOrderChange,
  };
};
