import { useTranslation } from 'react-i18next';
import BaseOperationTypography, { BoldOperationTypography } from '../../../Operation/BaseOperationTypography';
import { Operation } from '@/redux/slices/operationSlice';

export type FleetMessageProps = {
  opening: string;
  subject: string;
  entity: string;
  read: Operation['read'];
  status: Operation['status'];
};

export default function QNumberMessage({ opening, subject, entity, read, status }: FleetMessageProps) {
  const { t } = useTranslation();
  return (
    <BaseOperationTypography read={read} status={status}>
      <BaseOperationTypography read={read} status={status}>
        {opening}{' '}
      </BaseOperationTypography>
      <BoldOperationTypography read={read} status={status}>
        {subject}{' '}
      </BoldOperationTypography>
      <BaseOperationTypography read={read} status={status}>
        {t('to')}{' '}
      </BaseOperationTypography>
      <BoldOperationTypography read={read} status={status}>
        {entity}
      </BoldOperationTypography>
    </BaseOperationTypography>
  );
}
