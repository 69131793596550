/**
 * Temporary Feature Visibility Control
 *
 * JIRA: CO-863 (https://culligan.atlassian.net/jira/software/c/projects/CO/boards/117?selectedIssue=CO-863)
 *
 * Some features have been temporarily commented out in this component as they are
 * currently non-functional. This is a temporary measure until the features are fully
 * implemented and tested.
 *
 */
import { Fragment, useMemo, useState } from 'react';
import Slot from '../Base/Slot';
import AreaContainer from '@/components/Shared/Card/Area';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { useTranslation } from 'react-i18next';
import { getRemainingUsagePercentage } from '@/shared/domain/consumables';
import {
  isDepletable,
  isExpendable,
  SlottedConsumable,
} from '@culligan-iot/domain/culligan/device/class/slotted-consumable';
import { InvertColorsOffOutlined, InvertColorsOutlined } from '@mui/icons-material';
import { Option } from 'effect';
import withTooltip from '@/components/Shared/Tooltip/withTooltip';
import CulliganNumber from '@/components/Shared/CulliganNumber';

export default function Level({
  consumable,
  isOnline,
  handleSetMin,
  handleSetMax,
}: {
  consumable: SlottedConsumable;
  isOnline: boolean;
  handleSetMin: (subsetId: SlottedConsumable['subset']['id']) => void;
  handleSetMax: (subsetId: SlottedConsumable['subset']['id']) => void;
}) {
  const { t } = useTranslation();
  const [usageError, setUsageError] = useState(false);
  const isExpendableConsumable = isExpendable(consumable);
  const isDepletableConsumable = isDepletable(consumable);
  const label = isExpendableConsumable ? t('usageLeft') : t('fillLevel');
  const tooltipText = t('fillLevelTooltip');
  const usagePercentage = getRemainingUsagePercentage(consumable).pipe(
    Option.match({
      onSome: (value) => {
        if (usageError) setUsageError(false);
        return value;
      },
      onNone: () => {
        if (!usageError) setUsageError(true);
        return 0;
      },
    })
  );

  const CircularProgressInnerText = useMemo(() => {
    return withTooltip(Typography);
  }, []);

  return (
    <Slot sx={{ width: '100%', padding: 0 }}>
      <AreaContainer>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <TypographyWithTooltip
            anchorText={label}
            slots={{
              AnchorTextTypography: ({ children }) => (
                <Typography variant="body2" color="text.secondary">
                  {children}
                </Typography>
              ),
            }}
            tooltipText={tooltipText}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginY: 1,
            marginX: 'auto',
          }}
        >
          <CircularProgress
            variant="determinate"
            size={'100%'}
            value={100}
            thickness={5}
            sx={{ position: 'absolute', color: `hsl(${usagePercentage} 100% 65% / 0.25)` }}
          />
          <CircularProgress
            variant="determinate"
            size={'100%'}
            thickness={5}
            value={usagePercentage}
            sx={{
              color: `hsl(${usagePercentage} 61% 41% / 1)`,
              strokeLinecap: 'round',
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="body1"
              component="div"
              color={`hsl(${usagePercentage} 61% 41% / 1)`}
              fontWeight={800}
              fontSize={35}
              lineHeight={1.2}
              display="flex"
              alignItems="center"
            >
              {usagePercentage}
              <Typography style={{ fontSize: '20px' }}>%</Typography>
            </Typography>
            {isDepletableConsumable && (
              <Box display={'flex'} gap={0.5}>
                <CircularProgressInnerText
                  tooltipTitle={
                    <Fragment>
                      {consumable.state.level}{' '}
                      <Typography fontStyle={'italic'} variant="caption">
                        {consumable.subset.rangeFullCapacity._tag}
                      </Typography>
                    </Fragment>
                  }
                  variant="body2"
                  color={(theme) => theme.palette.text.disabled}
                  fontWeight={800}
                  fontSize={12}
                  lineHeight={1}
                >
                  <CulliganNumber value={consumable.state.level} /> of
                </CircularProgressInnerText>
                <CircularProgressInnerText
                  tooltipTitle={
                    <Fragment>
                      {consumable.subset.rangeFullCapacity.amount}{' '}
                      <Typography fontStyle={'italic'} variant="caption">
                        {consumable.subset.rangeFullCapacity._tag}
                      </Typography>
                    </Fragment>
                  }
                  variant="body2"
                  color={(theme) => theme.palette.text.disabled}
                  fontWeight={800}
                  fontSize={12}
                  lineHeight={1}
                >
                  <CulliganNumber value={consumable.subset.rangeFullCapacity.amount} />
                </CircularProgressInnerText>
              </Box>
            )}
            {isExpendableConsumable && (
              <CircularProgressInnerText
                tooltipTitle={
                  <Fragment>
                    {Math.max(consumable.subset.rangeExhaust.amount - consumable.state.usage, 0)}{' '}
                    <Typography fontStyle={'italic'} variant="caption">
                      {consumable.subset.rangeExhaust._tag}
                    </Typography>
                  </Fragment>
                }
                variant="body2"
                color={(theme) => theme.palette.text.disabled}
                fontWeight={800}
                fontSize={12}
                lineHeight={1}
              >
                <CulliganNumber value={Math.max(consumable.subset.rangeExhaust.amount - consumable.state.usage, 0)} />
              </CircularProgressInnerText>
            )}
          </Box>
        </Box>
      </AreaContainer>
      <AreaContainer>
        <Box>
          <Button
            onClick={() => handleSetMax(consumable.subset.id)}
            startIcon={<InvertColorsOutlined />}
            variant="outlined"
            disabled={true} //{!isOnline || usagePercentage === 100}
            sx={{
              width: '100%',
              fontSize: '14px',
              borderRadius: 1,
              border: '2px solid rgb(39 93 163)',
              marginBottom: 1,
              paddingX: 1,
              paddingY: 0.5,
              '&:hover': { border: '2px solid' },
            }}
          >
            {t('replenish')}
          </Button>
          <Button
            onClick={() => handleSetMin(consumable.subset.id)}
            startIcon={<InvertColorsOffOutlined />}
            variant="outlined"
            disabled={true} //{!isOnline || usagePercentage === 0}
            sx={{
              width: '100%',
              fontSize: '14px',
              borderRadius: 1,
              border: '2px solid rgb(39 93 163)',
              paddingX: 1,
              paddingY: 0.5,
              '&:hover': { border: '2px solid' },
            }}
          >
            {t('disable')}
          </Button>
        </Box>
      </AreaContainer>
    </Slot>
  );
}
