import { Schema as S } from 'effect';
import { Culligan } from '@culligan-iot/domain';
import { getTableFilters } from '@/components/Shared/Tables/utils';
import { TableRef } from '@/components/Shared/Tables/typings';
import { CONSUMABLE_CATEGORIES } from '@/shared/constants';

export namespace FleetConsumables {
  export const INITIAL_PAGE = 1 as const;
  export const INITIAL_PAGE_SIZE = 10 as const;
  export const DEBOUNCE_TIME = 400 as const;
  export const INITIAL_CATEGORY = CONSUMABLE_CATEGORIES.enums.Enhancements;

  export type FilterParams = typeof FilterParams.Type;
  const FilterParams = S.transform(S.Struct({ fields: S.String }), S.Array(S.String), {
    encode: (l) => ({ fields: l.join(',') }),
    decode: (o) => o.fields.split(','),
  });
  export const encodeFilterParams = S.encodeSync(FilterParams);

  export const LevelNames = S.Literal('lessThan20', 'from20To40', 'from40To60', 'from60To80', 'moreThan80');

  export const LevelRanges: Record<typeof LevelNames.Type, [number, number]> = {
    lessThan20: [0, 20],
    from20To40: [20, 40],
    from40To60: [40, 60],
    from60To80: [60, 80],
    moreThan80: [80, 100],
  };

  export const DeviceFieldKeys = {
    Model: 'model',
    Vendor: 'vendor',
    Customer: 'customer',
    Level: 'level',
    Category: 'category',
  } as const;

  export const TablePaginationKeys = {
    Page: 'page',
    Size: 'size',
    OrderBy: 'orderBy',
    Direction: 'direction',
  } as const;

  export const DeviceFilterKeys = {
    ...DeviceFieldKeys,
    ...TablePaginationKeys,
  } as const;

  export type Columns = typeof Columns.Type;
  const Columns = S.Union(Culligan.Device.Class.Base.Id, S.Literal('levels'));

  const TableFilters = getTableFilters(Columns);
  const BarFilters = S.Struct({
    [DeviceFieldKeys.Model]: S.optional(S.String),
    [DeviceFieldKeys.Vendor]: S.optional(S.String),
    [DeviceFieldKeys.Customer]: S.optional(S.String),
    [DeviceFieldKeys.Level]: S.optional(LevelNames),
    [DeviceFieldKeys.Category]: S.optional(CONSUMABLE_CATEGORIES),
  });

  const Filters = S.Struct({ ...TableFilters.fields, ...BarFilters.fields });

  export const encodeFilters = S.encodeEither(Filters);
  export const decodeFilters = S.decodeUnknownEither(Filters);

  export type DecodedFilters = typeof Filters.Type;
  export type EncodedFilters = typeof Filters.Encoded;
  export type FilterKey = keyof DecodedFilters;

  export type Table = TableRef<Culligan.Device.Class.Device>;

  export type ShoppingDialogDetails = {
    devices: Culligan.Device.Class.Device[];
    consumableType: typeof CONSUMABLE_CATEGORIES.Type | 'all';
  };

  export type ShoppingDialogProps = ShoppingDialogDetails & {
    onClose: Function;
  };
}
