/**
 * Temporary Feature Visibility Control
 *
 * JIRA: CO-863 (https://culligan.atlassian.net/jira/software/c/projects/CO/boards/117?selectedIssue=CO-863)
 *
 * Some features have been temporarily commented out in this component as they are
 * currently non-functional. This is a temporary measure until the features are fully
 * implemented and tested.
 *
 */
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { isEmptyOrUndefinedObject, isValidNumber } from '@/shared/utils';
import { alpha, Box, useTheme } from '@mui/material';
import Area, { AreaRow, DataBoundAreaRow } from '@/components/Shared/Card/Area';
import { useLazyGetOTAListByDeviceIdQuery } from '@/redux/api/admin/otaApiSlice';
import { Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Address from './InfoCard/Address';
import Identity from './InfoCard/Identity';
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';
import React from 'react';
import { CulliganCard } from '@/components/Shared/Card';
import { Circle } from './MapCircle';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { isQuench } from '@/shared/domain/device';

function getLocation(device: Device) {
  const { providedLocation, detectedLocation } = device;
  if (
    !isValidNumber(providedLocation?.lat) &&
    !isValidNumber(providedLocation?.lon) &&
    isEmptyOrUndefinedObject(detectedLocation)
  ) {
    return undefined;
  }

  return {
    lat: providedLocation?.lat ?? detectedLocation!.lat,
    lng: providedLocation?.lon ?? detectedLocation!.lon,
    ...(detectedLocation && !providedLocation?.lat ? { accuracy: detectedLocation.accuracy } : {}),
  };
}

function DeviceInfoCard({ device }: { device: Device }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [triggerGetOTAListByDeviceId] = useLazyGetOTAListByDeviceIdQuery();
  const stringAddress = Object.values(device?.installationAddress || {})
    .filter((a) => a)
    .join(', ');
  const qNumber = isQuench(device.vendor) ? device.vendor.qNumber : undefined;
  const location = useMemo(() => getLocation(device), [device]);

  const mapKey = useMemo(() => {
    if (!location) return `no-location-${device.id}`;
    return `${location.lat}-${location.lng}-${location?.accuracy || ''}-${device.id}`;
  }, [location, device.id]);

  const MemoizedMap = useMemo(() => {
    return (
      <Map
        key={mapKey}
        defaultZoom={15}
        {...(location ? { defaultCenter: location } : {})}
        mapId={`${device.id}-map`}
        disableDefaultUI={true}
        zoomControl={true}
        clickableIcons={false}
      >
        <RenderIf condition={!location}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                padding: 1,
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.6),
                color: (theme) => theme.palette.getContrastText(alpha(theme.palette.grey[900], 0.2)),
                borderRadius: 2,
              }}
            >
              {t('locationNotAvailable')}
            </Box>
          </Box>
        </RenderIf>
        <RenderIf condition={!isEmptyOrUndefinedObject(location)}>
          <AdvancedMarker key={device.id} position={location}>
            <Pin
              background={theme.palette.primary.main}
              glyphColor={theme.palette.text.primary}
              borderColor={theme.palette.primary.light}
            />
          </AdvancedMarker>
        </RenderIf>
        <RenderIf condition={!isEmptyOrUndefinedObject(location) && isValidNumber(location?.accuracy)}>
          <Circle
            center={location}
            radius={location?.accuracy}
            strokeWeight={0}
            fillColor={theme.palette.primary.main}
          />
        </RenderIf>
      </Map>
    );
  }, [
    device.id,
    location,
    mapKey,
    t,
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.text.primary,
  ]);

  useEffect(() => {
    triggerGetOTAListByDeviceId(device.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <CulliganCard id="deviceInfoCard" cardTitle={t('deviceInfo')} sx={{ position: 'relative' }}>
        <AreaRow sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Identity
            sx={{ gridColumnEnd: 'span 2' }}
            id={device.id}
            model={device.model}
            code={device.vendor.code}
            name={device.constructor.label}
            qNumber={qNumber}
            isQuenchDevice={isQuench(device.vendor)}
            manufacturingSerialNumber={device.manufacturingSerialNumber}
          />
        </AreaRow>

        {/* <AreaRow>
          <Configuration model={device.model} />
        </AreaRow> */}

        <AreaRow sx={{ flexBasis: 0, width: '100%' }}>
          <DataBoundAreaRow sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Address stringAddress={stringAddress} device={device} />
            <Area paddingRenderer="section" sx={{ width: '100%' }}>
              <Box sx={{ borderRadius: 1, overflow: 'hidden', height: '350px', flexGrow: 2, position: 'relative' }}>
                {MemoizedMap}
              </Box>
            </Area>
          </DataBoundAreaRow>
        </AreaRow>
      </CulliganCard>
    </Fragment>
  );
}

export default React.memo(DeviceInfoCard);
