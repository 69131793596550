/**
 * Temporary Feature Visibility Control
 *
 * JIRA: CO-863 (https://culligan.atlassian.net/jira/software/c/projects/CO/boards/117?selectedIssue=CO-863)
 *
 * Some features have been temporarily commented out in this component as they are
 * currently non-functional. This is a temporary measure until the features are fully
 * implemented and tested.
 *
 */
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFleetFiltersQuery } from '@/redux/api/fleet/fleetApiSlice';
import { useQueryStringFilters } from '@/hooks/useQueryStringFilters';
import { Array, Either, Option, pipe, Schema as S } from 'effect';

const FILTER_KEYS = {
  BRAND: 'brand',
  MODEL: 'model',
  CUSTOMER: 'customer',
  STATUS: 'status',
} as const;

const FiltersSchema = S.Struct({
  brand: S.optional(S.String),
  model: S.optional(S.String),
  customer: S.optional(S.String),
  status: S.optional(S.Literal('connected', 'disconnected')),
});

type Decoded = typeof FiltersSchema.Type;
type Encoded = typeof FiltersSchema.Encoded;

const encode = S.encodeEither(FiltersSchema);
const decode = S.decodeUnknownEither(FiltersSchema);

export default function useFleetOverviewFilters() {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetFleetFiltersQuery({
    keys: ['brands', 'models', 'status'],
  });
  const response = pipe(
    Option.fromNullable(data?.data),
    Option.getOrElse(() => [])
  );
  const { getQueryFilters, setQueryFilters, upsertQueryFilters, deleteQueryFilters } = useQueryStringFilters<
    Decoded,
    Encoded
  >({
    encode,
    decode,
  });
  const { brand, model, customer, status } = getQueryFilters();

  const brands = useMemo(
    () =>
      pipe(
        Array.findFirst(response, (item) => item._tag === 'brands'),
        Option.match({
          onNone: () => [],
          onSome: (item) => item.brands,
        })
      ),
    [response]
  );
  const models = useMemo(
    () =>
      pipe(
        Array.findFirst(response, (item) => item._tag === 'models'),
        Option.match({
          onNone: () => [],
          onSome: (item) => item.models,
        })
      ),
    [response]
  );
  const statuses = useMemo(
    () =>
      pipe(
        Array.findFirst(response, (item) => item._tag === 'status'),
        Option.match({
          onNone: () => [],
          onSome: (item) => item.status,
        })
      ),
    [response]
  );

  // const [trigger] = fleetApiSlice.endpoints.getFleetCustomer.useLazyQuery();

  // const fetchInitialCustomer = useCallback(async () => {
  //   if (!customer) {
  //     return;
  //   }

  //   const { customer: data } = await trigger({ customerId: customer }).unwrap();

  //   return pipe(
  //     data,
  //     Option.fromNullable,
  //     Option.match({
  //       onNone: () => ({ label: '', optionId: '' }),
  //       onSome: (customer) => ({
  //         label: `${customer.firstName} ${customer.lastName}`,
  //         optionId: customer.id,
  //       }),
  //     })
  //   );
  // }, [customer, trigger]);

  // const maybeBrand = useMemo(
  //   () =>
  //     brands?.length
  //       ? brands
  //           .filter((b) => b.id === brand)
  //           .map((b) => ({ label: b.name, optionId: b.id }))
  //           .at(0)
  //       : undefined,

  //   [brand, brands]
  // );

  const maybeModel = useMemo(
    () =>
      models?.length
        ? models
            .filter((b) => b.id === model)
            .map((b) => ({ label: b.name, optionId: b.id }))
            .at(0)
        : undefined,

    [models, model]
  );

  const maybeStatus = useMemo(
    () =>
      statuses?.length
        ? statuses
            .filter((s) => s === status)
            .map((b) => ({ label: t(b), optionId: b }))
            .at(0)
        : undefined,

    [status, statuses, t]
  );

  const handleFiltersApplied = useCallback(
    (filtersApplied: Map<string, string>) => {
      const getFilterApplied = (key: string) => Option.fromNullable(filtersApplied.get(key));
      const createPayload = (key: keyof Encoded, value: string) => [
        {
          key,
          value,
        } as const,
      ];

      const brandFilter = getFilterApplied(FILTER_KEYS.BRAND).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.String),
        Either.match({
          onLeft: () => [],
          onRight: (right) => createPayload(FILTER_KEYS.BRAND, right),
        })
      );

      const statusFilter = getFilterApplied(FILTER_KEYS.STATUS).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.Literal('connected', 'disconnected')),
        Either.match({
          onLeft: () => [],
          onRight: (right) => createPayload(FILTER_KEYS.STATUS, right),
        })
      );

      const modelFilter = getFilterApplied(FILTER_KEYS.MODEL).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.String),
        Either.match({
          onLeft: () => [],
          onRight: (right) => createPayload(FILTER_KEYS.MODEL, right),
        })
      );

      const customerFilter = getFilterApplied(FILTER_KEYS.CUSTOMER).pipe(
        Option.getOrNull,
        S.decodeUnknownEither(S.String),
        Either.match({
          onLeft: () => [],
          onRight: (right) => createPayload(FILTER_KEYS.CUSTOMER, right),
        })
      );

      const payload = [...brandFilter, ...modelFilter, ...statusFilter, ...customerFilter];

      setQueryFilters(payload);
    },
    [setQueryFilters]
  );

  const handleFiltersCleared = useCallback(() => {
    const all = Object.values(FILTER_KEYS);
    deleteQueryFilters(all);
  }, [deleteQueryFilters]);

  // const shouldFetchCustomer = useCallback((query: string) => query.length >= 3 && /[a-zA-Z]/g.test(query), []);
  // const parseCustomerFilterOption = useCallback((data: { items: UsersFilterResponse[] }) => {
  //   return data?.items?.map((item) => {
  //     const toSearchForMatches = data?.items.filter((user) => user.id !== item.id);
  //     const hasHomonym =
  //       toSearchForMatches?.length > 0 &&
  //       toSearchForMatches.findIndex(
  //         (user) =>
  //           user?.firstName?.toLowerCase() === item?.firstName?.toLowerCase() &&
  //           user?.lastName?.toLowerCase() === item?.lastName?.toLowerCase()
  //       ) !== -1;
  //     return { ...item, optionId: item.id, label: `${item?.firstName} ${item?.lastName}`, hasHomonym };
  //   });
  // }, []);

  // const getCustomerQueryParams = (query: string) => ({ query } as UsersFilterRequest);

  const filterConfig = useMemo(
    () => [
      {
        id: FILTER_KEYS.MODEL,
        label: t('deviceType'),
        kind: 'autocomplete',
        options:
          models?.map((model) => ({
            label: `${model.name} (${model.id})`,
            optionId: model.id,
          })) || [],
        defaultValue: maybeModel,
      },
      {
        id: FILTER_KEYS.STATUS,
        label: t('status'),
        kind: 'autocomplete',
        options:
          statuses?.map((s) => ({
            label: t(s),
            optionId: s,
          })) || [],
        defaultValue: maybeStatus,
      },
      // {
      //   id: FILTER_KEYS.BRAND,
      //   label: t('businessUnit'),
      //   kind: 'autocomplete',
      //   disabled: brands?.length <= 1,
      //   readOnly: brands?.length <= 1,
      //   options:
      //     brands?.map((brand) => ({
      //       label: brandNameMap.get(brand.name) || brand.name,
      //       optionId: brand.id,
      //     })) || [],
      //   defaultValue:
      //     maybeBrand ||
      //     (brands.length === 1 && {
      //       label: brands[0].name,
      //       optionId: brands[0].id,
      //     }),
      // },
      // {
      //   id: FILTER_KEYS.CUSTOMER,
      //   label: t('customer'),
      //   kind: 'asyncAutocomplete',
      //   shouldFetch: shouldFetchCustomer,
      //   transformFn: parseCustomerFilterOption,
      //   lazyQueryHook: useLazyGetUsersQuery,
      //   getQueryParam: getCustomerQueryParams,
      //   debounceTime: 400,
      //   getInitialValue: fetchInitialCustomer,
      //   renderOption: CustomerListItem,
      // },
    ],
    [
      // brands,
      // fetchInitialCustomer,
      // maybeBrand,
      maybeModel,
      maybeStatus,
      models,
      // parseCustomerFilterOption,
      // shouldFetchCustomer,
      statuses,
      t,
    ]
  );

  useEffect(() => {
    if (brands.length === 1) {
      upsertQueryFilters([{ key: FILTER_KEYS.BRAND, value: brands[0].id }]);
    }
  }, [brands, upsertQueryFilters]);

  return useMemo(
    () => ({
      filterConfig,
      handleFiltersApplied,
      handleFiltersCleared,
      isLoadingFilters: isLoading || isFetching,
      filters: {
        brand,
        model,
        customer,
        status,
      },
    }),
    [brand, customer, filterConfig, handleFiltersApplied, handleFiltersCleared, isFetching, isLoading, model, status]
  );
}
