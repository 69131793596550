import { Operation, STATUS } from '@/redux/slices/operationSlice';
import { useTranslation } from 'react-i18next';
import BaseOperationAvatar from '../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../Operation/BaseOperationIcon';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BaseOperationIconButton from '../../Operation/BaseOperationIconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CommonSnackbarProps } from '../typings';
import { StatusNotification } from '../../Notification/BaseNotification';
import BaseSnackbar from '../BaseSnackbar';
import StatusSnackbarActions from '../StatusSnackbarActions';
import useDefaultErrorCallback from '../../Notification/hooks/useDefaultErrorCallback';
import { getDefaultStatusIcon } from '../../Notification/ByTag/utils';
import DeviceCommandMessage from '../../Notification/ByTag/OneTool/Message';
import { useMemo } from 'react';

export default function DeviceAddressSnackbar({
  operation,
  dismissAction,
}: CommonSnackbarProps & { operation: Operation }) {
  const { t } = useTranslation();
  const defaultErrorCallback = useDefaultErrorCallback(operation);

  const message = useMemo(() => {
    const getOpening = () => {
      switch (operation.tag) {
        case 'device-address-create':
          if (operation.status === STATUS.ERROR) {
            return t('deviceAddress.notification.createdError');
          }
          return t('deviceAddress.notification.created');
        case 'device-address-update':
          if (operation.status === STATUS.ERROR) {
            return t('deviceAddress.notification.updatedError');
          }
          return t('deviceAddress.notification.updated');
        case 'device-address-delete':
          if (operation.status === STATUS.ERROR) {
            return t('deviceAddress.notification.deletedError');
          }
          return t('deviceAddress.notification.deleted');
        default:
          return '';
      }
    };

    return (
      <DeviceCommandMessage
        read={operation.read}
        status={operation.status}
        opening={getOpening()}
        entity={t(operation.entity)}
        subject={operation.subject}
      />
    );
  }, [operation.read, operation.status, operation.entity, operation.subject, operation.tag, t]);

  return (
    <StatusNotification
      {...operation}
      baseComponent={{
        Component: BaseSnackbar,
        extra: { snackbarActions: <StatusSnackbarActions operation={operation} dismissAction={dismissAction} /> },
      }}
      avatar={
        <BaseOperationAvatar
          status={operation.status}
          tagIcon={<BaseOperationIcon Icon={LocationOnIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(operation.status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          message: message,
          onClick: defaultErrorCallback,
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={operation.status}
                read={operation.read}
                icon={<BaseOperationIcon Icon={VisibilityIcon} color="inherit" />}
              />
            ),
          },
        },
        [STATUS.SUCCESS]: {
          message: message,
          onClick: () => '',
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={operation.status}
                read={operation.read}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
          },
        },
      }}
    />
  );
}
