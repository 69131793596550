/**
 * Temporary Feature Visibility Control
 *
 * JIRA: CO-863 (https://culligan.atlassian.net/jira/software/c/projects/CO/boards/117?selectedIssue=CO-863)
 *
 * Several features have been temporarily commented out in this component as they are
 * currently non-functional. This is a temporary measure until the features are fully
 * implemented and tested.
 *
 */
import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLazyGetDeviceQuery, useUpdateDeviceLoyaltyProgramMutation } from '@/redux/api/fleet/devicesApiSlice';
import FixedToolbar, { scrolledAmountIsGreaterThan } from '@/components/Shared/Toolbar';
import { CommandResponse, CommandType, useRequestTelemetryUpdateMutation } from '@/redux/api/fleet/telemetryApiSlice';
import AnimatedIcon from '@/components/Shared/AnimatedIcon';
import { useEffect, useLayoutEffect } from 'react';
import { selectPollingById, startPolling } from '@/redux/slices/pollingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { OPERATION_STATUS, ROUTES } from '@/shared/constants';
import { STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { ApiState } from '@typings';
import dayjs from 'dayjs';
import { AddCircleOutline } from '@mui/icons-material';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { useLazyGetLoyaltyProgramsQuery } from '@/redux/api/system/loyaltyProgramsApiSlice';
import TagIcon from '@mui/icons-material/Tag';
import { AreaBody } from '@/components/Shared/Card/Area';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import { Chip } from '@mui/material';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import useIsTablet from '@/hooks/useIsTablet';
import { DeviceContext } from './DeviceContext';
import { getPath } from '@/shared/utils';
import useCurrentTab from '@/hooks/useCurrentTab';
import { LoyaltyProgramId } from '@culligan-iot/domain/culligan/user/loyalty';
import { isQuench } from '@/shared/domain/device';
import QuenchNumberChip from './Toolbar/QuenchNumberChip';

const DevicePage = () => {
  const { t } = useTranslation();
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();
  const [triggerGetLoyaltyPrograms] = useLazyGetLoyaltyProgramsQuery();
  const _requestTelemetryUpdate = () => {
    requestTelemetryUpdate(deviceId!);
  };
  const theme = useTheme();
  const [triggerGetDevice, { isLoading, isSuccess, isError, error, data }] = useLazyGetDeviceQuery();
  const [updateLoyaltyProgram] = useUpdateDeviceLoyaltyProgramMutation();

  const [requestTelemetryUpdate, resultTelemetryUpdate] = useRequestTelemetryUpdateMutation();
  const requestId = useSelector((state) => {
    const mutations = (state as ApiState<CommandResponse<CommandType>>).api.mutations;

    return Object.values({ ...mutations })
      .filter((mutation) => mutation.endpointName === 'requestTelemetryUpdate')
      .sort((a, b) => {
        const timeStampA = (a as any).startedTimeStamp;
        const timeStampB = (b as any).startedTimeStamp;

        return timeStampB - timeStampA;
      })[0]?.requestId;
  });
  const polling = useSelector((state: AppState) => selectPollingById(state, requestId, 'getLatestTelemetryUpdate'));
  const manufacturingSerialNumber = data?.success ? data.data.manufacturingSerialNumber : null;

  useEffect(() => {
    if (
      data?.success &&
      !resultTelemetryUpdate.isError &&
      !resultTelemetryUpdate.isLoading &&
      resultTelemetryUpdate.isSuccess &&
      resultTelemetryUpdate.data?.data?.body &&
      !polling?.isPolling
    ) {
      dispatch(
        upsertOperation({
          entity: deviceId!,
          location: ' ',
          operationId: requestId,
          read: false,
          showed: false,
          state: OPERATION_STATUS.PENDING,
          status: STATUS.INFO,
          subject: t('telemetryUpdate'),
          timestamp: dayjs().valueOf(),
          uniqueId: `${requestId}-${STATUS.INFO}`,
          tag: TAGS.DEVICE_TELEMETRY_UPDATE,
        })
      );

      dispatch(
        startPolling({
          endpoint: 'getLatestTelemetryUpdate',
          args: {
            id: deviceId!,
            latestUpdate: +(data.data.lastTelemetryUpdate || 0),
          },
          id: requestId,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, dispatch, resultTelemetryUpdate]);

  useLayoutEffect(() => {
    triggerGetDevice(deviceId!);
  }, [deviceId, triggerGetDevice]);

  useLayoutEffect(() => {
    if (polling?.isPolling === false && polling?.result?.isSuccess) {
      triggerGetDevice(deviceId!, false);
    }
  }, [deviceId, polling?.isPolling, polling?.result?.isSuccess, triggerGetDevice]);

  const tabsConfig = [
    {
      id: ROUTES.FLEET_DEVICE_OVERVIEW.fragment,
      label: t(ROUTES.FLEET_DEVICE_OVERVIEW.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_OVERVIEW', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_ALARMS.fragment,
      label: t(ROUTES.FLEET_DEVICE_ALARMS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_ALARMS', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_ERRORS.fragment,
      label: t(ROUTES.FLEET_DEVICE_ERRORS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_ERRORS', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_EVENTS.fragment,
      label: t(ROUTES.FLEET_DEVICE_EVENTS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_EVENTS', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_CONSUMABLES.fragment,
      label: t(ROUTES.FLEET_DEVICE_CONSUMABLES.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_CONSUMABLES', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_TELEMETRY.fragment,
      label: t(ROUTES.FLEET_DEVICE_TELEMETRY.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_TELEMETRY', { deviceId: deviceId ?? null })),
      isLoading: polling?.isPolling,
    },
    {
      id: ROUTES.FLEET_DEVICE_OTA_JOBS.fragment,
      label: t(ROUTES.FLEET_DEVICE_OTA_JOBS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_OTA_JOBS', { deviceId: deviceId ?? null })),
    },
    // {
    //   id: ROUTES.FLEET_DEVICE_LOYALTY_PROGRAMS.fragment,
    //   label: t(ROUTES.FLEET_DEVICE_LOYALTY_PROGRAMS.fragment),
    //   useHash: false,
    //   onNavigate: () => navigate(getPath('FLEET_DEVICE_LOYALTY_PROGRAMS', { deviceId: deviceId ?? null })),
    // },
  ] satisfies TabConfig[];

  const { AddEditDialog, openDialog } = useAddEditDialog({
    title: 'Loyalty Program',
    baseConfig: [
      {
        name: 'loyaltyPrograms',
        type: 'autocomplete',
        selectConfig: {
          multiple: true,
          options: async () => {
            const data = await triggerGetLoyaltyPrograms();
            if (data && data.data?._tag === 'Right' && data.data.right.success) {
              return data.data.right.data.items.map((option) => ({
                label: option.name,
                value: option.id,
              }));
            }
            return [];
          },
        },
      },
    ],
    getEditData: async (deviceSerial) => {
      if (typeof deviceSerial === 'boolean') {
        return;
      }
      try {
        const { data } = await triggerGetDevice(deviceSerial);
        if (!data || !data.success) {
          console.error('Error while trying to fetch device data');
          return;
        }
        return { loyaltyPrograms: data.data.loyaltyPrograms };
      } catch (error) {
        console.error('Error while trying to fetch device data', error);
        return;
      }
    },
    onSubmit: async (_, loyaltyProgramsData: { loyaltyPrograms: LoyaltyProgramId[] }) => {
      if (!deviceId) {
        console.error('Device ID is not available');
        return;
      }

      if (!loyaltyProgramsData || !data?.success) {
        console.error('Device data is not available');
        return;
      }

      if (!loyaltyProgramsData.loyaltyPrograms.length) {
        console.error('Invalid data submitted:', loyaltyProgramsData);
        return;
      }

      try {
        const { data: loyaltyProgramsDefinitions } = await triggerGetLoyaltyPrograms();
        if (
          !loyaltyProgramsDefinitions ||
          loyaltyProgramsDefinitions._tag !== 'Right' ||
          !loyaltyProgramsDefinitions.right.success
        ) {
          console.error('Error while trying to fetch loyalty programs data');
          return;
        }
        const deviceLoyaltyProgramsIds = new Set(data?.data?.loyaltyPrograms || []);
        const submittedLoyaltyProgramIds = new Set(loyaltyProgramsData.loyaltyPrograms);
        const availableProgramIds = new Set(loyaltyProgramsDefinitions.right.data.items.map((program) => program.id));

        const programsToAdd = submittedLoyaltyProgramIds.difference(deviceLoyaltyProgramsIds);
        const programsToKeep = submittedLoyaltyProgramIds.intersection(availableProgramIds);
        const updatedLoyaltyPrograms = programsToKeep.union(programsToAdd);

        const payload = { loyaltyPrograms: Array.from(updatedLoyaltyPrograms) };
        await updateLoyaltyProgram({ serialNumber: deviceId, ...payload });
      } catch (error) {
        console.error('Unexpected error during loyalty program update:', error);
      }
    },
  });

  const { fragment, index } = useCurrentTab(tabsConfig);

  const toolbarBody = (
    <Box
      display="flex"
      flexDirection={isTablet ? 'column' : 'row'}
      justifyContent={'space-between'}
      alignItems={isTablet ? 'flex-start' : 'center'}
    >
      <Button
        id="backToList"
        variant="text"
        onClick={() => navigate('/fleet/devices')}
        sx={{ mb: isTablet ? '1rem' : 0, '@media print': { display: 'none' } }}
        startIcon={<ArrowCircleLeftIcon />}
      >
        {t('backToList')}
      </Button>

      {fragment === ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment && !isError && (
        <Box display="flex" ml={isTablet ? 0 : '4rem'} gap={1}>
          <Button
            variant="outlined"
            color={polling?.result?.isError ? 'error' : 'primary'}
            startIcon={
              <AnimatedIcon icon={<AddCircleOutline sx={{ mr: '0.6rem' }} />} trigger={Boolean(polling?.isPolling)} />
            }
            onClick={() => openDialog(deviceId)}
          >
            {t('manage') + ' ' + t(ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment)}
          </Button>
          <AddEditDialog />
        </Box>
      )}
    </Box>
  );

  return (
    <DeviceContext.Provider
      value={{
        data: data && data.success ? data?.data : undefined,
        consumables: data?.success ? data.data.consumables : undefined,
        slots: data?.success ? data.data.constructor.slots : undefined,
        isError: isError,
        isLoading: isLoading,
        error: error,
      }}
    >
      <FixedToolbar
        event={'scroll'}
        slideInPredicate={scrolledAmountIsGreaterThan(100)}
        body={toolbarBody}
        sx={{ '@media print': { display: 'none' } }}
      />
      <Box>
        <Card
          sx={{
            flex: 1,
            overflowY: 'auto',
            padding: 1,
            '@media print': { display: 'none' },
          }}
          elevation={1}
        >
          {toolbarBody}
        </Card>
        <Box mt={'4rem'} my={'2rem'} sx={{ '@media print': { marginTop: '30mm' }, fontWeight: 'bold' }}>
          <AreaBody
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'start',
              paddingBottom: '0.5rem',
            }}
          >
            {data?.success && data.data.connectionStatus.online && (
              <Box
                width={15}
                height={15}
                sx={{
                  backgroundColor: `${data?.data.connectionStatus.online ? 'success' : 'error'}.light`,
                  borderRadius: '50%',
                  marginRight: '0.5rem',
                }}
              />
            )}
            {data?.success ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  '@media print': { alignItems: 'flex-start' },
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  id="deviceName"
                  sx={{ '@media print': { fontSize: '2rem', fontWeight: '700' } }}
                >
                  {data?.data?.constructor.label}
                </Typography>
                <Button
                  sx={{ '@media print': { display: 'none' } }}
                  variant="outlined"
                  color={polling?.result?.isError ? 'error' : 'primary'}
                  disabled={data?.success ? !data?.data?.connectionStatus.online || polling?.isPolling : true}
                  startIcon={<AnimatedIcon icon={<RefreshIcon />} trigger={Boolean(polling?.isPolling)} />}
                  onClick={() => _requestTelemetryUpdate()}
                >
                  {t('requestUpdate')}
                </Button>
              </Box>
            ) : null}
          </AreaBody>
          <AreaBody
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '0.5rem', flexWrap: 'wrap' }}
          >
            <RenderIf condition={isSuccess}>
              <CopyChip
                sx={{
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                }}
                icon={<TagIcon color="primary" />}
                label={data?.success ? data.data.id : ''}
              />
              {data?.success && isQuench(data?.data?.vendor) && <QuenchNumberChip vendor={data?.data?.vendor} />}
              {manufacturingSerialNumber ? (
                <CopyChip
                  sx={{ fontWeight: 'bold' }}
                  label={manufacturingSerialNumber}
                  icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                />
              ) : (
                <Chip
                  icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                  label={t('manufacturingSerialNumberUnassigned')}
                  size="small"
                />
              )}
            </RenderIf>
          </AreaBody>
        </Box>
        {index !== 0 && <NavigationTabsLayout config={tabsConfig} defaultIndex={index - 1} />}
        <Outlet />
      </Box>
    </DeviceContext.Provider>
  );
};

export default DevicePage;
