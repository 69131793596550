/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-redeclare */
import { Schema as S } from 'effect';
import { Ota } from '@culligan-iot/domain/culligan/device/index';

export type OtaBuildReq = typeof OtaBuildReq.Type;
export type OtaBuildReqEncoded = typeof OtaBuildReq.Encoded;
export const OtaBuildReq = Ota.Build.pipe(
  S.pick('id', 'model', 'version', 'minVersion'),
  S.extend(S.Struct({ timestamp: S.optional(Ota.Build.fields.timestamp) }))
);

export type OtaBuildRes = typeof Ota.Build.Type;
export type OtaBuildResEncoded = typeof OtaBuildRes.Encoded;
export const OtaBuildRes = Ota.Build;
