import { Schema as S } from 'effect';
import { Culligan } from '@culligan-iot/domain';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FleetConsumables } from '@/components/Fleet/Consumables/ConsumablesPanel/typings';
import { handleDecodeUnknownEither } from '@/redux/utils';
import { responseBodySuccess } from '@culligan-iot/domain/culligan/api/v2';

const GenericFilterResponse = S.Struct({
  id: S.NonEmptyString,
  name: S.NonEmptyString,
});

const FiltersApiResponse = S.Struct({
  vendors: S.Array(GenericFilterResponse),
  models: S.Array(GenericFilterResponse),
  customer: S.Struct({
    id: S.NonEmptyString,
    firstName: S.NonEmptyString,
    lastName: S.optional(S.NonEmptyString),
  }),
  categories: S.Array(GenericFilterResponse),
}).annotations({
  identifier: 'FiltersApiResponse',
  parseIssueTitle: () => 'Error parsing filters data: ',
});

const DevicesApiResponse = S.Struct({
  itemsCount: S.NonNegative,
  pagesCount: S.Positive,
  page: S.Positive,
  items: S.mutable(S.Array(Culligan.Device.Class.Device)),
}).annotations({
  identifier: 'DevicesApiResponse',
  parseIssueTitle: () => 'Error parsing devices data: ',
});

const DevicesApiResult = responseBodySuccess(DevicesApiResponse);
const FiltersApiResult = responseBodySuccess(FiltersApiResponse);

const decodeGetDevicesResponse = S.decodeUnknownEither(DevicesApiResult, { errors: 'all' });
const decodeGetFiltersResponse = S.decodeUnknownEither(FiltersApiResult, { errors: 'all' });

const devicesWithConsumablesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetDevicesWithConsumables', 'Fleet', 'DevicesWithConsumablesFilters'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevicesWithConsumables: builder.query<typeof DevicesApiResult.Type, FleetConsumables.DecodedFilters>({
        query: ({ level, ...params }) => {
          return {
            url: `${API_VERSION.v2}/fleet/devices/consumables`,
            params: {
              ...params,
              min: level && FleetConsumables.LevelRanges[level][0],
              max: level && FleetConsumables.LevelRanges[level][1],
            },
          };
        },
        transformResponse: (response) => decodeGetDevicesResponse(response).pipe(handleDecodeUnknownEither),
        providesTags: ['FleetDevicesWithConsumables', 'Fleet'],
      }),
      getConsumablesFilters: builder.query<typeof FiltersApiResult.Type, FleetConsumables.FilterParams>({
        query: (params) => ({
          url: `${API_VERSION.v2}/fleet/devices/filters`,
          params: FleetConsumables.encodeFilterParams(params),
        }),
        transformResponse: (response) => decodeGetFiltersResponse(response).pipe(handleDecodeUnknownEither),
        providesTags: ['DevicesWithConsumablesFilters'],
      }),
    }),
    overrideExisting: 'throw',
  });

export const { useLazyGetDevicesWithConsumablesQuery, useGetConsumablesFiltersQuery } = devicesWithConsumablesApiSlice;
