import { ROLES, ROLES_PRIORITIES } from '@/shared/constants';
import { AuthState } from '@/redux/slices/authSlice';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { API_VERSION } from './apiSlice';

const urlsMapping = {
  getDevices: {
    [`${API_VERSION.v2}/admin/device/registry/list`]: [ROLES.ADMIN, ROLES.SYS_ADMIN],
    [`${API_VERSION.v1}/device/registry/list`]: [
      ROLES.ADMIN,
      ROLES.BRAND_MANAGER,
      ROLES.BRAND_TECH_OPERATOR,
      ROLES.BRAND_SUPPORT_OPERATOR,
    ],
  },
  getDevicesWithConsumables: {
    [`${API_VERSION.v2}/admin/device/registry/list/consumables`]: [ROLES.ADMIN, ROLES.SYS_ADMIN],
  },
  getDevice: {
    [`${API_VERSION.v2}/admin/device/registry`]: [ROLES.ADMIN, ROLES.SYS_ADMIN],
    [`${API_VERSION.v1}/device/registry/details`]: [
      ROLES.ADMIN,
      ROLES.BRAND_MANAGER,
      ROLES.BRAND_TECH_OPERATOR,
      ROLES.BRAND_SUPPORT_OPERATOR,
    ],
  },
  getSwVersion: {
    [`${API_VERSION.v2}/admin/device/swVersion`]: [ROLES.ADMIN, ROLES.SYS_ADMIN],
  },
  getDeviceConnectionHistory: {
    [`${API_VERSION.v1}/admin/device/connection-history`]: [
      ROLES.SYS_ADMIN,
      ROLES.ADMIN,
      ROLES.BRAND_MANAGER,
      ROLES.BRAND_TECH_OPERATOR,
      ROLES.BRAND_SUPPORT_OPERATOR,
    ],
  },
  getDeviceOTAJobList: {
    [`${API_VERSION.v2}/admin/device/ota/job/list`]: [ROLES.ADMIN, ROLES.SYS_ADMIN],
  },
  patchUpdateLoyaltyPrograms: {
    [`${API_VERSION.v2}/admin/device/registry/loyalty-programs`]: [
      ROLES.SYS_ADMIN,
      ROLES.ADMIN,
      ROLES.BRAND_MANAGER,
      ROLES.BRAND_TECH_OPERATOR,
      ROLES.BRAND_SUPPORT_OPERATOR,
    ],
  },
  patchContactInfo: {
    [`${API_VERSION.v2}/admin/device/contact-info`]: [ROLES.ADMIN, ROLES.SYS_ADMIN],
  },
};

/** It extracts the highest role the logged user has. */
const getRoleBaseUrl = async (endpointName: string, store: any) => {
  const {
    auth: { roles },
  } = store.getState() as { auth: AuthState };

  const rolesPrioritiesWithoutUser = ROLES_PRIORITIES.filter((role) => role !== 'user') as Exclude<
    (typeof ROLES_PRIORITIES)[number],
    'user'
  >[];

  const role = rolesPrioritiesWithoutUser.find((role) => roles.includes(role));

  if (!role) {
    throw new Error('UNAUTHORIZED_ROLE');
  }

  const candidateUrls = urlsMapping[endpointName as keyof typeof urlsMapping];

  const [extractedUrl] = Object.entries(candidateUrls).filter(([_, roles]) => roles.includes(role));

  if (!extractedUrl || extractedUrl.length < 0) {
    throw new Error('NO_ROUTES_AVAILABLE');
  }

  return extractedUrl[0];
};

/** This function has to be used in all endpoints defined in the API slices that require mapping.  */
export const roleAwareQuery =
  (endpointName: string, baseQueryOpts?: (arg: any) => Parameters<BaseQueryFn>[0] | Parameters<BaseQueryFn>[0]) =>
  async (arg: any, api: any, _: any, baseQuery: any) => {
    const options = baseQueryOpts ? (typeof baseQueryOpts === 'function' ? baseQueryOpts(arg) : baseQueryOpts) : {};
    const url = (await getRoleBaseUrl(endpointName, api)) as string;
    return baseQuery({
      url,
      ...options,
    }) as any;
  };
