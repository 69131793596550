import { ApiFailureResult, DecodeResult, ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import {
  CommonIsEntityUniqueBase,
  decodeCommonIsEntityUniqueResponse,
  IsEntityUniqueApiResult,
} from '@/components/OneTools/typings';
import { Culligan, Entity } from '@culligan-iot/domain';
import { Schema as S } from 'effect';

const URL_PREFIX = `${API_VERSION.v2}/system/loyalty-programs`;

const StringifiedDates = S.Struct({
  createdAt: S.String,
  updatedAt: S.String,
});

export const LoyaltyProgramWithStringifiedDates = Culligan.User.Loyalty.LoyaltyProgram.pipe(
  S.omit('createdAt', 'updatedAt'),
  S.extend(StringifiedDates)
);

export const LoyaltyProgramWithoutDates = Entity.newEntity(Culligan.User.Loyalty.LoyaltyProgram).annotations({
  identifier: 'LoyaltyProgramWithoutDates',
});

const _LoyaltyProgramApiResponse = () => {
  return S.Union(
    ApiFailureResult,
    S.Struct({
      success: S.Literal(true),
      data: LoyaltyProgramWithStringifiedDates,
    })
  );
};

const GetLoyaltyProgramsApiResultData = S.Struct({
  items: S.Array(LoyaltyProgramWithStringifiedDates),
}).annotations({
  identifier: 'GetLoyaltyProgramsApiResultData',
});

const GetLoyaltyProgramsApiResult = ApiResult(GetLoyaltyProgramsApiResultData).annotations({
  identifier: 'GetLoyaltyProgramsApiResult',
});

const decodeLoyaltyProgramApiResponse = S.decodeUnknownEither(_LoyaltyProgramApiResponse(), { errors: 'all' });
const decodeLoyaltyProgramsApiResponse = S.decodeUnknownEither(GetLoyaltyProgramsApiResult, { errors: 'all' });

export const LoyaltyProgramsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['LoyaltyPrograms'] }).injectEndpoints({
  endpoints: (builder) => ({
    getLoyaltyPrograms: builder.query<DecodeResult<typeof GetLoyaltyProgramsApiResult.Type>, void>({
      //
      query: () => URL_PREFIX + '/list',
      providesTags: ['LoyaltyPrograms'],
      transformResponse: (response) => {
        return decodeLoyaltyProgramsApiResponse(response);
      },
    }),
    getLoyaltyProgram: builder.query<
      DecodeResult<S.Schema.Type<ReturnType<typeof _LoyaltyProgramApiResponse>>>,
      string
    >({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['LoyaltyPrograms'],
      transformResponse: (response) => {
        return decodeLoyaltyProgramApiResponse(response);
      },
    }),
    isLoyaltyProgramUnique: builder.query<DecodeResult<typeof IsEntityUniqueApiResult.Type>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
      transformResponse: (response) => {
        return decodeCommonIsEntityUniqueResponse(response);
      },
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useLazyGetLoyaltyProgramsQuery,
  useGetLoyaltyProgramsQuery,
  useLazyGetLoyaltyProgramQuery,
  useLazyIsLoyaltyProgramUniqueQuery,
} = LoyaltyProgramsApiSlice;
