import { useDispatch, useSelector } from 'react-redux';
import { upsertOperation, STATUS, TAGS } from '@/redux/slices/operationSlice';
import dayjs from 'dayjs';
import Area, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import TagIcon from '@mui/icons-material/Tag';
import { Box, Chip, IconButton, SxProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { Quench } from '@culligan-iot/domain/culligan/device/vendor/index';
import SettingsIcon from '@mui/icons-material/Settings';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { useUpdateDeviceQNumberMutation } from '@/redux/api/fleet/devicesApiSlice';
import { Either, Schema } from 'effect';
import { QNumber } from '@culligan-iot/domain/culligan/device/vendor/quench';
import { useState } from 'react';
import { selectRoles } from '@/redux/slices/authSlice';
import { AppState } from '@/redux/store';
import { ROLES } from '@/shared/constants';

interface IdentityProps {
  id: Device['id'];
  model: Device['model'];
  code: Device['vendor']['code'];
  name: string;
  sx?: SxProps;
  manufacturingSerialNumber: Device['manufacturingSerialNumber'];
  qNumber?: Quench.DeviceInfo['qNumber'];
  isQuenchDevice: boolean;
}

interface IdentityFormData {
  name: string;
  qNumber?: string;
  manufacturingSerialNumber?: string;
}
const decodeQNumber = Schema.decodeUnknownEither(QNumber, { errors: 'all' });

function Identity({ id, code, model, name, sx, manufacturingSerialNumber, qNumber, isQuenchDevice }: IdentityProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [updateQNumber] = useUpdateDeviceQNumberMutation();
  const [stateQNumber, setStateQNumber] = useState<string | undefined>(qNumber);

  const isAdmin = useSelector((state: AppState) => selectRoles(state)).some(
    (role) => role === ROLES.SYS_ADMIN || role === ROLES.ADMIN
  );

  const createNotification = (
    status: (typeof STATUS)[keyof typeof STATUS],
    tag: (typeof TAGS)[keyof typeof TAGS],
    error?: any
  ) => {
    const operationId = `qnumber-${id}-${dayjs().valueOf()}`;

    dispatch(
      upsertOperation({
        entity: id,
        location: stateQNumber ?? 'New QNumber',
        operationId,
        read: false,
        showed: false,
        subject: t('deviceQnumber'),
        timestamp: dayjs().valueOf(),
        tag,
        state: status === STATUS.SUCCESS ? 'fulfilled' : 'rejected',
        status,
        uniqueId: `${operationId}-${status}`,
        error: error ?? null,
      })
    );
  };

  const validateQNumber = (value: unknown) => {
    if (value === '' || value === undefined) {
      return true;
    }
    const decodedQNumber = decodeQNumber(value);
    if (Either.isLeft(decodedQNumber)) {
      return t('deviceQnumberFormatMessage');
    }
    return true;
  };

  const { AddEditDialog, openDialog } = useAddEditDialog<IdentityFormData>({
    title: t('deviceIdentity'),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          disabled: true,
        },
      },
      {
        name: 'code',
        placeholder: t('code'),
        options: {
          disabled: true,
        },
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t<string, string>('fieldRequiredError'),
          disabled: true,
        },
      },
      {
        name: 'qNumber',
        placeholder: t('deviceQnumber'),
        options: {
          disabled: false,
          validate: validateQNumber,
        },
      },
      {
        name: 'manufacturingSerialNumber',
        placeholder: t('deviceManufacturingSerialNumber'),
        options: {
          disabled: true,
        },
      },
    ],
    getEditData: async () => {
      return {
        name,
        code: (code + model).toUpperCase(),
        id,
        qNumber: stateQNumber,
        manufacturingSerialNumber: manufacturingSerialNumber ?? 'M-SN unassigned',
      };
    },
    onSubmit: async (_, formData) => {
      if (formData.qNumber === stateQNumber) {
        return;
      }

      const newQNumber = !formData.qNumber ? undefined : formData.qNumber;
      const tag = !newQNumber ? TAGS.QNUMBER_DELETE : stateQNumber ? TAGS.QNUMBER_UPDATE : TAGS.QNUMBER_CREATE;

      try {
        const result = await updateQNumber({
          serialNumber: id,
          qNumber: newQNumber,
        });

        if ('error' in result) {
          throw result.error;
        }
        setStateQNumber(newQNumber);
        createNotification(STATUS.SUCCESS, tag);
      } catch (error) {
        createNotification(STATUS.ERROR, tag, error);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const handleOpenDialog = () => {
    openDialog(id);
  };

  return (
    <>
      <Area
        {...(isQuenchDevice && {
          onClick: () => {
            handleOpenDialog();
          },
        })}
        sx={{ width: '100%', height: '100%', ...(sx ? sx : {}) }}
      >
        <AreaHeader>
          <TypographyWithTooltip
            anchorText={t('identity')}
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            tooltipText={t('deviceIdentityTooltip')}
          />
          {isAdmin && isQuenchDevice && (
            <IconButton size="small" onClick={handleOpenDialog} aria-label="settings" data-testid="settings-button">
              <SettingsIcon />
            </IconButton>
          )}
        </AreaHeader>
        <AreaBody>
          <Title>{`${name || '--'}`}</Title>
          <Box
            sx={{ fontWeight: 'bold', paddingTop: '0.5rem' }}
            display="flex"
            flexWrap="wrap"
            gap={AREA_DESIGN_TOKENS.gap}
            alignItems="flex-end"
          >
            <CopyChip
              icon={<TagIcon color="primary" />}
              sx={{
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              }}
              label={id}
            />
            <CopyChip label={code && model ? code + model : '--'} icon={<TagIcon />} />
            {isQuenchDevice &&
              (stateQNumber ? (
                <CopyChip
                  sx={{ fontWeight: 'bold' }}
                  label={stateQNumber}
                  icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                />
              ) : (
                <Chip
                  icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                  label={t('qUnassigned')}
                  size="small"
                />
              ))}
            {manufacturingSerialNumber ? (
              <CopyChip
                sx={{ fontWeight: 'bold' }}
                label={manufacturingSerialNumber}
                icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
              />
            ) : (
              <Chip
                icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                label={t('manufacturingSerialNumberUnassigned')}
                size="small"
              />
            )}
          </Box>
        </AreaBody>
      </Area>

      <AddEditDialog />
    </>
  );
}

export default Identity;
