import { Box, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import RelatedEntityLabel from '../../Chips/RelatedEntityChip';
import DialogContentLabel from '../DialogContentLabel';

export default function IssueDevice({ data: { deviceId } }: { data: { deviceId: string } }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <DialogContent
      sx={(theme) => ({ p: '2rem', background: theme.palette.background.grayShades[0], marginBottom: '1.5rem' })}
    >
      <Box>
        <Typography variant="overline">Device:</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="baseline">
          <DialogContentText textAlign="left" variant="h6">
            {deviceId}
          </DialogContentText>
          <RelatedEntityLabel text="Open" onEntityClick={() => navigate(`/fleet/devices/${deviceId}`)} size="small" />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '0.2rem' }}>
        <DialogContentLabel variant="caption">{t('id')}:</DialogContentLabel>
        <DialogContentText variant="caption">{deviceId}</DialogContentText>
      </Box>
    </DialogContent>
  );
}
