import { getAlarmLabel } from '@/shared/domain/alarm';
import { Match } from 'effect';
import { useTranslation } from 'react-i18next';
import { IssueDetails } from './types';
import { Box, DialogContentText } from '@mui/material';
import DialogContentLabel from '../DialogContentLabel';

export default function IssueLabel({ data }: { data: IssueDetails.Data }) {
  const { t } = useTranslation();

  const label = Match.value(data).pipe(
    Match.when({ _tag: 'FleetOverviewAlarm' }, ({ alarm }) => getAlarmLabel(alarm._tag)),
    Match.when({ _tag: 'FleetDevice' }, ({ alarm }) => getAlarmLabel(alarm._tag)),
    Match.when({ _tag: 'FleetAlarms' }, ({ alarm }) => getAlarmLabel(alarm._tag)),
    Match.when({ _tag: 'FleetOverviewError' }, ({ name }) => name),
    Match.when({ _tag: 'FleetError' }, ({ name }) => name),
    Match.exhaustive
  );

  return (
    <Box>
      <DialogContentLabel>{t('name')}:</DialogContentLabel>
      <DialogContentText textAlign="left" variant="body2">
        {label}
      </DialogContentText>
    </Box>
  );
}
