import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import DeviceAlarmCounter from '../DevicePanel/DeviceAlarmCounter';
import BlipChip from '@/components/Shared/Chips/BlipChip';
import TagIcon from '@mui/icons-material/Tag';
import { ROUTES } from '@/shared/constants';
import { isQuench } from '@/shared/domain/device';
import { FleetDevices } from './typings';

/**
 * Hook to get a stable reference of the table columns.
 * IMPORTANT. The columns must be stored either in order of preference:
 * 1 - Outside of a react component in case of dumb columns.
 * 2 - Inside a ref.
 * 3 - Inside a readonly useState with no setter.
 * @returns Fleet devices table columns.
 */
export const useDevicesTableColumns = (): Array<Column<FleetDevices.DeviceWithLabel>> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderModel = useCallback(
    (device: FleetDevices.DeviceWithLabel) => {
      return (
        <Box>
          <Typography variant="subtitle1">{device.label}</Typography>
          <Box display="flex" gap={1} alignItems="center" sx={{ flexWrap: 'wrap' }}>
            <Chip icon={<TagIcon />} label={device.model} size="small" />
            <Chip icon={<TagIcon />} label={device.id} size="small" />
            {isQuench(device.vendor) && (
              <Chip
                icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                label={device.vendor?.qNumber ?? t('qUnassigned')}
                size="small"
              />
            )}
            <Chip
              icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
              label={device?.manufacturingSerialNumber || t('manufacturingSerialNumberUnassigned')}
              size="small"
            />
          </Box>
        </Box>
      );
    },
    [t]
  );

  const renderStatus = useCallback(
    (device: FleetDevices.DeviceWithLabel) => (
      <Box>
        {!device.connectionStatus || Object.keys(device.connectionStatus).length === 0 ? (
          t('notDefined')
        ) : device.connectionStatus.online === true ? (
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: 'transparent' }}>
                <Box
                  sx={(theme) => ({
                    height: '80%',
                    bgcolor: theme.palette.success.light,
                    width: '80%',
                    borderRadius: '50%',
                  })}
                />
              </Avatar>
            }
            label={t('connected')}
            size="small"
            color="success"
            variant="outlined"
          />
        ) : (
          <BlipChip label={t('disconnected')} size="small" color="error" variant="outlined" />
        )}
      </Box>
    ),
    [t]
  );

  const renderErrors = useCallback(
    (device: FleetDevices.DeviceWithLabel) => <DeviceAlarmCounter serialNumber={device.id} data={device.errors} />,
    []
  );
  const renderAlarms = useCallback(
    (device: FleetDevices.DeviceWithLabel) => <DeviceAlarmCounter serialNumber={device.id} data={device.alarms} />,
    []
  );
  const renderAction = useCallback(
    (device: FleetDevices.DeviceWithLabel) => (
      <Button
        key={`buttonDevicesTable${device.id}`}
        variant="outlined"
        color="primary"
        onClick={() => navigate(`/fleet/devices/${device.id}`)}
      >
        {t('view')}
      </Button>
    ),
    [navigate, t]
  );

  const columns = useRef<Column<FleetDevices.DeviceWithLabel>[]>([
    {
      title: t('modelTypeId'),
      width: 'max-content',
      cellStyle: { textAlign: 'left' },
      field: 'model',
      render: renderModel,
    },
    {
      title: t('connectionStatus'),
      field: 'status',
      render: renderStatus,
      export: true,
    },
    { title: t('fwVersion'), field: 'swVersion', width: '8%' },
    {
      title: t('errors'),
      field: 'errors',
      render: renderErrors,
    },
    {
      title: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
      field: 'alarms',
      width: 'max-content',
      render: renderAlarms,
    },
    {
      title: t('actions'),
      field: 'actions',
      width: '5%',
      sorting: false,
      searchable: false,
      export: false,
      render: renderAction,
    },
  ]);
  return columns.current;
};
