import { useCallback, useRef } from 'react';

export default function useDebounce<T>(callback: ((value: T) => void) | (() => void), delay: number) {
  const timeoutId = useRef<number | undefined>();
  return useCallback(
    (value?: T, _delay?: number) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(
        () => (value !== undefined ? callback(value) : (callback as () => void)()),
        _delay ?? delay
      ) as unknown as number;
    },
    [callback, delay]
  );
}
