/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-redeclare */
import { Direction } from '@/components/Shared/Tables/typings';
import { Schema as S } from 'effect';
import { StatusT } from '@typings';
import { AlarmsTableFields } from './Alarms/useAlarmsFilters';
import { ErrorsTableFields } from './Errors/useErrorsFilters';
import { FleetDevices } from '../Devices/DevicesPanel/typings';
import { Severity } from '@culligan-iot/domain/culligan/device/capability/alarm';
import { User } from '@culligan-iot/domain/culligan/index';
import { Device } from '@culligan-iot/domain/culligan/device/class/base';

export type FilterAlarmRequest = {
  start: number;
  end: number;
  severity?: Severity;
  status?: StatusT;
  customer?: User.Id;
  model?: FleetDevices.Device['model'];
  vendor?: Device['vendor']['code'];
  page: number;
  size: number;
  orderBy?: AlarmsTableFields;
  direction?: Direction;
};

export type FilterErrorRequest = {
  start: number;
  end: number;
  status?: StatusT;
  customer?: User.Id;
  vendor?: Device['vendor']['code'];
  page?: number;
  size?: number;
  model?: FleetDevices.Device['model'];
  orderBy?: ErrorsTableFields;
  direction?: Direction;
};

export type FleetError = typeof FleetError.Type;
export const FleetError = S.Struct({
  time: S.Number,
  id: S.String,
  deviceId: S.String,
  deviceName: S.String,
  dismissed: S.Boolean,
  dismissedAt: S.NullOr(S.DateFromString),
  name: S.String,
  createdAt: S.String,
});
