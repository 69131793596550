import OverviewCard from './DeviceCardInfo';
import { Box, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContactInfo } from '@culligan-iot/domain/culligan/device/class/base';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { useUpdateDeviceContactInfoMutation } from '@/redux/api/fleet/devicesApiSlice';
import { STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

const ContactInfoTypography = styled(Typography)(() => ({
  lineHeight: '1.2rem',
}));

const ContactInfoHeadingTypography = styled(ContactInfoTypography)(() => ({
  fontWeight: 600,
  textAlign: 'right',
}));

const ContactInfoValueTypography = styled(ContactInfoTypography)(() => ({
  marginLeft: '2rem',
}));

function ContactInfoCard({ contactInfo, deviceId }: { contactInfo: ContactInfo | null; deviceId: string }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [postContactInfo] = useUpdateDeviceContactInfoMutation();

  const createNotification = (status: (typeof STATUS)[keyof typeof STATUS], error?: any) => {
    const operationId = `contactinfo-${deviceId}-${dayjs().valueOf()}`;

    dispatch(
      upsertOperation({
        entity: deviceId,
        location: 'Contact Info',
        operationId,
        read: false,
        showed: false,
        subject: t('contactInfo'),
        timestamp: dayjs().valueOf(),
        tag: TAGS.CONTACT_INFO_UPDATE,
        state: status === STATUS.SUCCESS ? 'fulfilled' : 'rejected',
        status,
        uniqueId: `${operationId}-${status}`,
        error: error ?? null,
      })
    );
  };

  const { AddEditDialog: ContactInfoDialog, openDialog } = useAddEditDialog<typeof ContactInfo.Type>({
    title: t('Contact Info'),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        initialValue: contactInfo?.name || undefined,
        options: { required: t('fieldRequiredError') as string },
      },
      {
        name: 'email',
        placeholder: t('email'),
        type: 'email',
        initialValue: contactInfo?.email || undefined,
      },
      {
        placeholder: t('phoneNumber'),
        name: 'phoneNumber',
        type: 'tel',
        initialValue: contactInfo?.phoneNumber || undefined,
      },
    ],
    onSubmit: async (dialogId: string | boolean, contactInfo) => {
      try {
        const result = await postContactInfo({
          contactInfo,
          serialNumber: deviceId,
        });
        if ('error' in result) {
          throw result.error;
        }
        createNotification(STATUS.SUCCESS);
      } catch (error) {
        createNotification(STATUS.ERROR, error);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  return (
    <>
      <OverviewCard
        id="deviceContactInfoCard"
        title={t('contactInfo')}
        cardContentProps={{
          sx: {
            ':last-child': {
              p: theme.spacing(2),
            },
          },
        }}
        actionLabel={t('edit')!}
        onActionClick={() => {
          openDialog();
        }}
      >
        {contactInfo && (contactInfo.name || contactInfo.email || contactInfo.phoneNumber) ? (
          <Box display="grid" gridTemplateColumns="1fr 2fr" gap="1rem" my="0.5rem" mx="3rem" alignItems="center">
            <ContactInfoHeadingTypography variant="body1">{t('contactName')}</ContactInfoHeadingTypography>
            <ContactInfoValueTypography variant="body1">{contactInfo.name}</ContactInfoValueTypography>

            <ContactInfoHeadingTypography variant="body1">{t('contactEmail')}</ContactInfoHeadingTypography>
            <ContactInfoValueTypography variant="body1">{contactInfo.email}</ContactInfoValueTypography>

            <ContactInfoHeadingTypography variant="body1">{t('contactPhone')}</ContactInfoHeadingTypography>
            <ContactInfoValueTypography variant="body1">{contactInfo.phoneNumber}</ContactInfoValueTypography>
          </Box>
        ) : (
          `${t('noContactInfoForDevice')}`
        )}
      </OverviewCard>
      <ContactInfoDialog />
    </>
  );
}

export default ContactInfoCard;
