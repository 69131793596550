import { Schema as S } from 'effect';
import { ApiResult, Job } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { OtaBuildReq, OtaBuildRes } from '@/components/OneTools/Ecosystem/OTA/typings';
import { roleAwareQuery } from '../roleAwareQuery';
import { OtaJobsRes } from '@/components/Fleet/Devices/DevicePanel/OtaJobsPanel/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { ROUTES } from '@/shared/constants';
import { handleDecodeUnknownEither } from '@/redux/utils';

const URL_PREFIX = `${API_VERSION.v2}/admin/device/ota`;
const URL_PREFIX_BUILD = `${URL_PREFIX}/build`;
const URL_PREFIX_PLURAL = `${URL_PREFIX_BUILD}s`;

export type OTAJob = {
  name: string;
  version: string;
  buildFile: string;
  targetSelectionMode: 'filter' | 'deviceGroup';
  enabled: boolean;
  targetFilterParams: {
    serialNumbers: string[];
  };
};

export const GetOtaBuildsResponse = ApiResult(S.Struct({ items: S.Array(OtaBuildRes) })).annotations({
  identifier: 'GetOtaBuildsResponse',
  parseIssueTitle: () => 'Error parsing OTA builds data: ',
});

const decodeGetOtaBuildsResponse = S.decodeUnknownEither(GetOtaBuildsResponse, { errors: 'all' });

export const GetOtaBuildResponse = ApiResult(OtaBuildRes).annotations({
  identifier: 'GetOtaBuildResponse',
  parseIssueTitle: () => 'Error parsing OTA build data: ',
});

const decodeGetOtaBuildResponse = S.decodeUnknownEither(GetOtaBuildResponse, { errors: 'all' });

export const OTAApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['OTA', 'OTAJob'] }).injectEndpoints({
  endpoints: (builder) => ({
    getOTAList: builder.query<ApiResult<{ items: ReadonlyArray<OtaBuildRes> }>, void>({
      query: () => `${URL_PREFIX_PLURAL}/search`,
      transformResponse: (response) => decodeGetOtaBuildsResponse(response).pipe(handleDecodeUnknownEither),
      providesTags: ['OTA'],
    }),
    getOTAListByDeviceId: builder.query<ApiResult<{ items: ReadonlyArray<OtaBuildRes> }>, string>({
      query: (id: string) => ({
        url: `${URL_PREFIX}/list`,
        params: { id },
      }),
      transformResponse: (response) => decodeGetOtaBuildsResponse(response).pipe(handleDecodeUnknownEither),
    }),
    getOTA: builder.query<ApiResult<OtaBuildRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX_BUILD,
        params: { id },
      }),
      transformResponse: (response) => decodeGetOtaBuildResponse(response).pipe(handleDecodeUnknownEither),
      providesTags: ['OTA'],
    }),
    getOTAJobsDevicesList: builder.query<ApiResult<{ items: OtaJobsRes[] }>, string>({
      queryFn: roleAwareQuery('getDeviceOTAJobList', (arg) => ({
        params: { serialNumber: arg },
      })),
      providesTags: ['OTA', 'OTAJob'],
    }),
    getBuildFileDownloadURL: builder.query<ApiResult<{ item: string }>, string>({
      query: (id: string) => ({
        url: `${URL_PREFIX_PLURAL}/url`,
        params: { buildFile: id },
      }),
    }),
    getOTAJobProgress: builder.query<ApiResult<{ items: Job[] }>, string>({
      query: (id) => ({
        url: `${URL_PREFIX}/job/progress`,
        params: { id },
      }),
    }),
    isOTAUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, Required<Pick<OtaBuildRes, 'id'>>>({
      query: (params: Required<Pick<OtaBuildRes, 'id'>>) => ({
        url: `${URL_PREFIX_BUILD}/unique`,
        params,
      }),
    }),
    postOTAJob: builder.mutation<ApiResult<{ item: { id: string } }>, OTAJob>({
      query: (body: OTAJob) => {
        const { version, ...rest } = body;
        return {
          url: `${URL_PREFIX}/job`,
          method: 'POST',
          body: rest,
        };
      },
    }),
    postOTA: builder.mutation<ApiResult<OtaBuildRes>, OtaBuildReq>({
      query: (body: OtaBuildReq) => ({
        url: `${URL_PREFIX_BUILD}/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: `${URL_PREFIX_BUILD}/create`,
          method: 'POST',
          subject: arg.id,
          entity: ROUTES.ONETOOL_ECOSYSTEM_OTA.fragment,
        });
      },
    }),
    putOTA: builder.mutation<ApiResult<OtaBuildRes>, { id: string; body: OtaBuildReq }>({
      query: ({ id, body }: { id: string; body: OtaBuildReq }) => ({
        url: `${URL_PREFIX_BUILD}/put`,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: `${URL_PREFIX_BUILD}/put`,
          method: 'PUT',
          subject: arg.body.id,
          entity: ROUTES.ONETOOL_ECOSYSTEM_OTA.fragment,
        });
      },
    }),
    patchOTA: builder.mutation<ApiResult<OtaBuildRes>, { id: string; body: Partial<OtaBuildReq> }>({
      query: ({ id, body }) => ({
        url: `${URL_PREFIX_BUILD}/patch`,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX_BUILD,
          method: 'PATCH',
          subject: arg.id,
          entity: ROUTES.ONETOOL_ECOSYSTEM_OTA.fragment,
        });
      },
    }),
    deleteOTA: builder.mutation<ApiResult<null>, string>({
      query: (id: string) => ({
        url: URL_PREFIX_BUILD,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX_BUILD,
          method: 'DELETE',
          subject: arg,
          entity: ROUTES.ONETOOL_ECOSYSTEM_OTA.fragment,
        });
      },
    }),
    uploadBuildFile: builder.mutation<{ success: true; error: null }, FormData>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const signedURLResponse = await fetchWithBQ({
          url: `${URL_PREFIX_PLURAL}/upload-url`,
          params: {
            model: _arg.get('model'),
            buildFile: _arg.get('id'),
            version: _arg.get('version'),
          },
        });
        if (signedURLResponse.error) return { error: signedURLResponse.error as FetchBaseQueryError };
        let signedURL;
        if (signedURLResponse.data) {
          signedURL = (signedURLResponse.data as ApiResult<{ item: string }>).data?.item;
        }

        const result = await fetchWithBQ({
          url: signedURL || '',
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
            'x-ms-blob-type': 'BlockBlob', // mandatory for file upload in Azure
          },
          body: _arg.get('buildFile'),
        });
        return result.meta?.response?.status === 201
          ? { data: { success: true, error: null } }
          : { error: result.error as FetchBaseQueryError };
      },
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetOTAListQuery,
  useGetOTAJobsDevicesListQuery,
  useLazyGetOTAJobsDevicesListQuery,
  useLazyGetOTAListQuery,
  useLazyGetOTAListByDeviceIdQuery,
  useGetOTAQuery,
  useLazyGetOTAQuery,
  useLazyGetBuildFileDownloadURLQuery,
  useLazyGetOTAJobProgressQuery,
  useLazyIsOTAUniqueQuery,
  usePostOTAJobMutation,
  usePostOTAMutation,
  usePutOTAMutation,
  usePatchOTAMutation,
  useDeleteOTAMutation,
  useUploadBuildFileMutation,
} = OTAApiSlice;
