import { Column } from '@material-table/core';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OtaBuildRes } from './typings';
import RelatedEntityLabel from '@/components/Shared/Chips/RelatedEntityChip';
import { useNavigate } from 'react-router';
import DownloadBuildFileButton from './DownloadBuildFileButton';
import { getPath } from '@/shared/utils';
import { deviceLabelByModel, deviceModelCodes } from '@/shared/domain/device';

export function useOTAColumns(onEditClick: Function, onDeleteClick: Function): Array<Column<OtaBuildRes>> {
  const { t } = useTranslation();
  const columns_array: Array<Column<OtaBuildRes>> = [];

  const navigate = useNavigate();

  return columns_array.concat([
    {
      title: t('buildFile'),
      field: 'id',
    },
    {
      title: t('deviceIdentity'),
      field: 'model',
      render: ({ model }) =>
        deviceModelCodes.includes(model) ? (
          <RelatedEntityLabel
            text={deviceLabelByModel[model]}
            onEntityClick={() =>
              navigate(getPath('ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES'), {
                state: {
                  searchText: deviceLabelByModel[model],
                  highlight: {
                    refField: 'id',
                    matchValue: model,
                  },
                  switchTab: true,
                },
              })
            }
          />
        ) : null,
    },
    {
      title: t('version'),
      field: 'version',
      render: ({ version }) => version.format(),
    },
    {
      title: t('minVersion'),
      field: 'minVersion',
      render: ({ minVersion }) => minVersion?.format(),
    },
    {
      title: t('actions'),
      render: (OTA) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          <DownloadBuildFileButton id={OTA.id} />
          <Button variant="outlined" color="primary" onClick={() => onEditClick(OTA.id)}>
            {t('edit')}
          </Button>
          <Button variant="outlined" color="error" onClick={() => onDeleteClick({ id: OTA.id, name: OTA.id })}>
            {t('delete')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    },
  ]);
}
