import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle } from '@/components/Shared/Card/Area/Text';
import { Box, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import WarningIcon from '@mui/icons-material/Warning';
import SettingsIcon from '@mui/icons-material/Settings';
import { OtaBuildRes, OtaBuildResEncoded } from '@/components/OneTools/Ecosystem/OTA/typings';
import React from 'react';
import dayjs from 'dayjs';
import { AppState } from '@/redux/store';
import { selectHasPendingOTAUpdate, selectPendingOTAUpdate } from '@/redux/slices/deviceSlice';
import { useSelector } from 'react-redux';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';

function Firmware({
  id,
  canOTAUpdate,
  isDeviceOnline,
  handleFirmwareAreaClick,
  handleFirmwareAreaNavigation,
  currentOTAVersion,
  displayedOTAVersion,
  openOTADialog,
  tooltipMessage,
  hasPendingOTAUpdate,
  sx,
  otaPollingRequestedAt,
}: {
  id: Device['id'];
  canOTAUpdate: boolean;
  isDeviceOnline: boolean;
  openOTADialog: (message: string) => void;
  handleFirmwareAreaClick: () => void;
  handleFirmwareAreaNavigation: () => void;
  firmwareVersion: string;
  tooltipMessage: string;
  hasPendingOTAUpdate: boolean;
  currentOTAVersion: OtaBuildRes | null;
  displayedOTAVersion: OtaBuildResEncoded;
  sx?: SxProps;
  otaPollingRequestedAt?: number;
}) {
  const { t } = useTranslation();
  const timeDifference = dayjs(otaPollingRequestedAt).fromNow();
  const isPollingOTAUpdate = useSelector((state: AppState) => selectHasPendingOTAUpdate(state, id));
  const targetVersion = useSelector((state: AppState) => selectPendingOTAUpdate(state, id))?.payload;

  return (
    <Area
      data-testid="firmware-area"
      disabled={!canOTAUpdate}
      onClick={() => handleFirmwareAreaClick()}
      sx={{ width: '100%', height: '100%', ...(sx ? sx : {}) }}
    >
      <AreaHeader>
        <TypographyWithTooltip
          anchorText={t('firmwareVersion')}
          slots={{
            AnchorTextTypography: Subtitle,
          }}
          tooltipText={t('firmwareVersionTooltip')}
        />
        <Box display={'flex'}>
          <IconButton
            data-testid="open-in-new-button"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleFirmwareAreaNavigation();
            }}
          >
            <OpenInNewIcon />
          </IconButton>

          <RenderIf condition={currentOTAVersion == null && displayedOTAVersion.id !== 'deletedOTAplaceholder'}>
            <Tooltip
              title={t(
                'firmwareVersionWarning',
                currentOTAVersion?.minVersion ? { minVersion: currentOTAVersion.minVersion.format() } : {}
              )}
              arrow
            >
              <Box>
                <IconButton size="small" disabled={true}>
                  <WarningIcon data-testid="warning-icon" color="inherit" />
                </IconButton>
              </Box>
            </Tooltip>
          </RenderIf>
          <RenderIf condition={currentOTAVersion != null || displayedOTAVersion.id === 'deletedOTAplaceholder'}>
            <Tooltip title={tooltipMessage} arrow>
              <Box>
                <IconButton
                  data-testid="settings-icon-button"
                  size="small"
                  onClick={() => openOTADialog(' ')}
                  disabled={!isDeviceOnline || hasPendingOTAUpdate || !canOTAUpdate}
                >
                  <SettingsIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </RenderIf>
        </Box>
      </AreaHeader>
      <AreaBody>
        <Box
          color="textPrimary"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 0.5,
            '@media (min-width:1216px)': {
              flexDirection: 'column',
              alignItems: 'start',
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {currentOTAVersion ? currentOTAVersion.version.format() : displayedOTAVersion.version}
          </Typography>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 0.5,
              width: '100%',
            }}
          >
            {isPollingOTAUpdate && (
              <Box
                component="span"
                sx={{
                  borderRadius: 2,
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 0.5,
                  p: 1,
                  backgroundColor: (theme) => theme.palette.background.paper,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box>
                  <Typography variant="body1" sx={{ fontSize: '0.7rem' }}>
                    {t('upgrading')}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.6rem' }} color="textSecondary">
                    {t('textOtaUpgrading', { timePollingRequest: timeDifference })}
                  </Typography>
                </Box>
                {targetVersion ? <ChevronRightIcon /> : null}
              </Box>
            )}
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {isPollingOTAUpdate && targetVersion ? targetVersion : ''}
            </Typography>
          </Box>
        </Box>
      </AreaBody>
    </Area>
  );
}
export default React.memo(Firmware);
