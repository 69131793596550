import { Match } from 'effect';
import { IssueDetails } from './types';
import { useTranslation } from 'react-i18next';
import { Box, DialogContentText } from '@mui/material';
import TypographyWithTooltip from '../../Tooltip';
import DialogContentLabel from '../DialogContentLabel';
import dayjs from 'dayjs';

export default function IssueDate({ data }: { data: IssueDetails.Data }) {
  const { t } = useTranslation();

  const reportingDate = Match.value(data).pipe(
    Match.when({ _tag: 'FleetOverviewAlarm' }, ({ createdAt }) => createdAt),
    Match.when({ _tag: 'FleetDevice' }, ({ createdAt }) => createdAt),
    Match.when({ _tag: 'FleetAlarms' }, ({ createdAt }) => createdAt),
    Match.when({ _tag: 'FleetOverviewError' }, ({ time }) => new Date(time)),
    Match.when({ _tag: 'FleetError' }, ({ time }) => new Date(time)),
    Match.exhaustive
  );

  return (
    <Box>
      <TypographyWithTooltip
        anchorText={`${t('reportingDate')}: `}
        slots={{
          AnchorTextTypography: DialogContentLabel,
        }}
        tooltipText={t('reportingDateTooltip')}
      />

      <Box display="flex" gap={'1rem'} alignItems="baseline">
        <DialogContentText>{dayjs(reportingDate).format('YYYY/MM/DD')}</DialogContentText>
        <Box>
          <DialogContentText variant="body2" fontStyle="italic">
            {dayjs(reportingDate).utc().fromNow()}
          </DialogContentText>
        </Box>
      </Box>
    </Box>
  );
}
