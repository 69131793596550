import { Schema as S, Either } from 'effect';
import { ApiResult, PaginatedResponse } from '@typings';
import {
  DeviceConnectionHistoryItem,
  Device as LegacyDevice,
  FleetDevices,
  UpdateDeviceLoyaltyProgram,
} from '@/components/Fleet/Devices/DevicesPanel/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { roleAwareQuery } from '@/redux/api/roleAwareQuery';
import { addDevice } from '@/redux/slices/deviceSlice';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { handleDecodeUnknownEither } from '@/redux/utils';
import { DeviceList, Device as DeviceListDevice } from '@culligan-iot/domain/culligan/api/device/deviceList';
import { Quench } from '@culligan-iot/domain/culligan/device/vendor/index';
import { Culligan } from '@culligan-iot/domain';
import { ContactInfo } from '@culligan-iot/domain/culligan/device/class/base';

type UpdateDeviceLoyaltyProgramRequest = {
  loyaltyPrograms: string[];
  serialNumber: string;
};

type UpdateDeviceContactInfoRequest = {
  contactInfo: ContactInfo;
  serialNumber: Device['id'];
};

export const GetDeviceResponse = ApiResult(Device).annotations({
  identifier: 'GetDeviceResponse',
  parseIssueTitle: () => 'Error parsing device data: ',
});

export const InstallationAddress = S.extend(
  S.Struct({
    address: S.NonEmptyString.pipe(S.minLength(2)).annotations({
      message: () => ({
        message: 'Address must be at least 2 characters long',
        override: true,
      }),
    }),
    city: S.NonEmptyString.annotations({
      message: () => ({
        message: 'City must be a non empty string',
        override: true,
      }),
    }),
    zip: S.NonEmptyString.annotations({
      message: () => ({
        message: 'Zipcode must be a non empty string',
        override: true,
      }),
    }),
  }),
  Culligan.Device.Class.Base.CommonProps.fields.installationAddress.pick(
    'addressExtra',
    'state',
    'country',
    'countryCode'
  )
).annotations({
  identifier: 'InstallationAddress',
});

const ProvidedLocation = Culligan.Device.Class.Base.CommonProps.fields.providedLocation.annotations({
  identifier: 'ProvidedLocation',
});

export const PutInstallationAddress = S.Struct({
  installationAddress: InstallationAddress,
  providedLocation: ProvidedLocation,
}).annotations({
  identifier: 'PutInstallationAddress',
});

export const decodePutInstallationAddress = S.decodeUnknownEither(PutInstallationAddress, {
  errors: 'all',
});

const decodeGetDeviceResponse = S.decodeUnknownEither(GetDeviceResponse, { errors: 'all' });

const id = (x: any) => x;
const decodeDeviceList = S.decodeUnknownEither(
  S.Struct({
    success: S.Boolean,
    data: DeviceList,
  })
);
const decodePaginatedDeviceList = S.decodeUnknownEither(
  S.Struct({
    success: S.Boolean,
    data: S.Struct({
      itemsCount: S.Int.pipe(S.nonNegative()),
      pagesCount: S.Int.pipe(S.nonNegative()),
      page: S.Int.pipe(S.nonNegative()),
      items: DeviceList,
    }),
  })
);

const devicesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetDevices', 'Fleet', 'Device', 'FleetDevice', 'DevicesFilters'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevices: builder.query<ApiResult<{ items: LegacyDevice[] }>, void>({
        queryFn: roleAwareQuery('getDevices'),
        providesTags: ['FleetDevices', 'Fleet'],
      }),
      getDevice: builder.query<typeof GetDeviceResponse.Type, Device['id']>({
        query: (id) => `${API_VERSION.v2}/admin/device/registry?serialNumber=${id}&domainOutput=true`,
        transformResponse: (response) => decodeGetDeviceResponse(response).pipe(handleDecodeUnknownEither),
        providesTags: ['FleetDevices', 'FleetDevice', 'Fleet', 'Device'],
        onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
          const result = await cacheDataLoaded;
          const device = result.data.success ? result.data.data : null;
          if (device) {
            dispatch(
              addDevice({
                serialNumber: arg,
                payload: {
                  model: device.model,
                  online: device.connectionStatus.online || false,
                  operatingMode: device.state.operatingMode,
                  pendingCommands: [],
                },
              })
            );
          }
        },
      }),
      getFilteredDevices: builder.query<ApiResult<DeviceList>, FleetDevices.DecodedFilters>({
        query: (params) => ({
          url: `${API_VERSION.v2}/fleet/devices/all`,
          params,
        }),
        transformResponse: (response) => decodeDeviceList(response).pipe(Either.getOrThrowWith(id)),
      }),
      getPaginatedFilteredDevices: builder.query<
        ApiResult<PaginatedResponse<DeviceListDevice>>,
        FleetDevices.DecodedFilters
      >({
        query: (params) => ({
          url: `${API_VERSION.v2}/fleet/devices`,
          params,
        }),
        transformResponse: (response) => decodePaginatedDeviceList(response).pipe(Either.getOrThrowWith(id)),
      }),
      getProtocolVersion: builder.query<ApiResult<{ swVersion: string }>, string>({
        queryFn: roleAwareQuery('getSwVersion', (serialNumber) => ({
          params: { serialNumber },
        })),
      }),
      getDeviceConnectionHistory: builder.query<ApiResult<{ items: DeviceConnectionHistoryItem[] }>, string>({
        queryFn: roleAwareQuery('getDeviceConnectionHistory', (serialNumber) => ({
          params: { serialNumber },
        })),
        providesTags: ['FleetDevices', 'Fleet'],
      }),
      updateDeviceLoyaltyProgram: builder.mutation<
        ApiResult<UpdateDeviceLoyaltyProgram>,
        UpdateDeviceLoyaltyProgramRequest
      >({
        queryFn: roleAwareQuery(
          'patchUpdateLoyaltyPrograms',
          ({ serialNumber, loyaltyPrograms }: UpdateDeviceLoyaltyProgramRequest) => ({
            params: { serialNumber },
            body: { loyaltyPrograms },
            method: 'PATCH',
          })
        ),
        invalidatesTags: ['FleetDevices'],
      }),
      updateDeviceQNumber: builder.mutation<
        ApiResult<Device>,
        { serialNumber: Device['id']; qNumber: Quench.DeviceInfo['qNumber'] }
      >({
        query: ({ serialNumber, qNumber }) => ({
          params: { serialNumber },
          url: `${API_VERSION.v2}/admin/device/registry/q-number`,
          method: 'PATCH',
          body: { qNumber },
        }),
        invalidatesTags: ['FleetDevices'],
      }),
      putDeviceInstallationAddress: builder.mutation<
        ApiResult<Device>,
        {
          serialNumber: Device['id'];
          addressAndProvidedLocation: typeof PutInstallationAddress.Type;
        }
      >({
        query: ({ serialNumber, addressAndProvidedLocation }) => ({
          url: `${API_VERSION.v2}/admin/device/registry/installation-address`,
          method: 'PUT',
          params: { serialNumber },
          body: addressAndProvidedLocation,
        }),
        invalidatesTags: ['FleetDevices', 'FleetDevice', 'Device'],
      }),
      deleteDeviceInstallationAddress: builder.mutation<ApiResult<Device>, { serialNumber: Device['id'] }>({
        query: ({ serialNumber }) => ({
          url: `${API_VERSION.v2}/admin/device/registry/installation-address`,
          method: 'DELETE',
          params: { serialNumber },
        }),
        invalidatesTags: ['FleetDevices', 'FleetDevice', 'Device'],
      }),
      updateDeviceContactInfo: builder.mutation<ApiResult<Device>, UpdateDeviceContactInfoRequest>({
        queryFn: roleAwareQuery(
          'patchContactInfo',
          ({ serialNumber, contactInfo }: UpdateDeviceContactInfoRequest) => ({
            params: { serialNumber },
            body: contactInfo,
            method: 'PUT',
          })
        ),
        invalidatesTags: ['Device'],
      }),
    }),
    overrideExisting: 'throw',
  });

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useGetFilteredDevicesQuery,
  useLazyGetDeviceQuery,
  useLazyGetPaginatedFilteredDevicesQuery,
  useLazyGetProtocolVersionQuery,
  useGetDeviceConnectionHistoryQuery,
  useUpdateDeviceLoyaltyProgramMutation,
  useUpdateDeviceQNumberMutation,
  usePutDeviceInstallationAddressMutation,
  useDeleteDeviceInstallationAddressMutation,
  useUpdateDeviceContactInfoMutation,
} = devicesApiSlice;

export default devicesApiSlice;
