import { RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import router from './router/routes';
import { store, persistedStore } from './redux/store';
import './App.css';
import { useTranslation } from 'react-i18next';
import { setDayJsLocale } from './shared/utils';
import '@fontsource/roboto/400.css'; // font-weight: regular
import '@fontsource/roboto/500.css'; // font-weight: medium
import '@fontsource/roboto/700.css'; // font-weight: bold
import '@fontsource/roboto/400-italic.css'; // font-style: italic
import '@fontsource/roboto/700-italic.css'; // font-style: italic

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useMemo } from 'react';

// Dayjs
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import UTC from 'dayjs/plugin/utc';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(UTC);
dayjs.extend(isBetweenPlugin);
dayjs.extend(isLeapYear);

function App() {
  const { i18n } = useTranslation();
  const localeAdapter = useMemo(() => dayjs.locale(), []);

  useEffect(() => {
    setDayJsLocale(i18n.language);
  }, [i18n.language]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeAdapter}>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistedStore}>
          <RouterProvider router={router} />
        </PersistGate>
      </ReduxProvider>
    </LocalizationProvider>
  );
}

export default App;
