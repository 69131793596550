import useEventsColumns from './useEventsColumns';
import GenericExportStaticTable from '@/components/Shared/Tables/GenericExportTable';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/shared/constants';
import { events } from '@culligan-iot/domain/culligan/device/capability/event';
import { Option, Record, SchemaAST } from 'effect';
import { EventRes } from './typings';

export const EventsTable = GenericExportStaticTable<EventRes>;

const getEventDefinitions = () => {
  return Record.toEntries(events).map(([name, EventClass]) => ({
    id: EventClass.example._tag,
    name,
    description: Option.getOrElse(SchemaAST.getDescriptionAnnotation(EventClass.ast.to), () => '-'),
    parameters: Boolean(EventClass.fields.params),
    payloadExample: JSON.stringify(EventClass.example, null, 2),
  }));
};

export default function EventsPanel() {
  const { t } = useTranslation();
  const columns = useEventsColumns();
  const eventsData = getEventDefinitions();

  return (
    <div className="space-y-4">
      <EventsTable
        title={t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment)}
        data={eventsData}
        columns={columns}
        exportData={true}
        selection={true}
      />
    </div>
  );
}
