import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import { useAlarmColumns } from './useAlarmsColumn';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { Column, MaterialTableProps } from '@material-table/core';
import { ROUTES } from '@/shared/constants';
import { useMemo } from 'react';
import { Device } from '@culligan-iot/domain/culligan/device/class/index';
import { Alarm } from '@culligan-iot/domain/culligan/device/history';
import { FleetOverview } from '../../Overview/typings';

export const SafeTable = withErrorLoadingManagement(GenericExportTable<Alarm | Device['alarms'][number]>);

export type AlarmContext = 'fleet' | 'device' | 'overview';

/**
 * Association between `AlarmContext` and Type of the Alarm rendered in the table.
 */
export type AlarmContextMap = {
  fleet: Alarm;
  device: Device['alarms'][number];
  overview: FleetOverview.Alarm;
};

type AlarmTableProps = {
  /**
   * We iterate each `kind` of alarm context to render the table
   * based on the kind of alarm context.
   */
  [K in AlarmContext]: {
    kind: K; // Alarm context
    /**
     * The alarmContextMap defines the association between the schema of the alarm and the kind of context.
     * `AlarmContextMap[K]`indexes the schema of the `items` prop.
     * E.G: `AlarmContextMap['fleet']` indexes the schema of the `items` prop.
     */
    items: readonly AlarmContextMap[K][];
    onDetailClick?: (deviceId: string) => void;
    slots?: {
      dialog: () => ReturnType<typeof useCulliganDialog>['dialog'];
    };
    excludeColumns?: string[];
    isLoading: boolean;
    isError: boolean;
    error?: unknown;
  } & Omit<MaterialTableProps<any>, 'data' | 'columns'>;
}[AlarmContext];

const areFleetColumns = (kind: AlarmContext, cols: readonly unknown[]): cols is Column<Alarm>[] =>
  cols.filter((_) => kind === 'fleet').length > 0;
const areFleetItems = (kind: AlarmContext, items: readonly unknown[]): items is Alarm[] =>
  Boolean(items.filter((_) => kind === 'fleet'));
const areDeviceColumns = (kind: AlarmContext, cols: readonly unknown[]): cols is Column<Device['alarms'][number]>[] =>
  cols.filter((_) => kind === 'device').length >= 0;
const areDeviceItems = (kind: AlarmContext, items: readonly unknown[]): items is Device['alarms'] =>
  items.filter((_) => kind === 'device').length >= 0;
const areOverviewColumns = (kind: AlarmContext, cols: readonly unknown[]): cols is Column<FleetOverview.Alarm>[] =>
  cols.filter((_) => kind === 'overview').length >= 0;
const areOverviewItems = (kind: AlarmContext, items: readonly unknown[]): items is FleetOverview.Alarm[] =>
  Boolean(items.filter((_) => kind === 'overview'));

export default function AlarmsTable(props: AlarmTableProps) {
  const { t } = useTranslation();
  const columns = useAlarmColumns({
    kind: props.kind,
    onDetailClick: props.onDetailClick,
    excludeColumns: props?.excludeColumns,
  });

  /**
   *
   * FIXME: For the time being, the `exportData` prop has been set to `false` to avoid runtime errors.
   * Export is broken due to the `alarm` prop being now a domain `Alarm` class instance.
   * At the moment, alarms now ara `Class` instances, and the `GenericExportTable` component.
   * This is because, underneath, the exporter of the `Material-Table-Core` fork
   * spreads the data object, and the class instance is not spreadable.
   *
   * We should either migrate to the the new table soulution or
   * globally handle Alarm transformation into a plain object.
   *
   * Refs: https://github.com/mbrn/filefy/blob/master/src/csv-builder/CsvBuilder.ts
   * Refs: https://culligan.atlassian.net/browse/CO-663
   */
  const table = useMemo(() => {
    switch (true) {
      case areFleetColumns(props.kind, columns) && areFleetItems(props.kind, props.items):
        return (
          <GenericExportTable<Alarm>
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            data={props.items}
            columns={columns}
            exportData={false}
            selection={true}
          />
        );
      case areDeviceColumns(props.kind, columns) && areDeviceItems(props.kind, props.items):
        return (
          <SafeTable
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            hasData={props.items.length > 0}
            data={
              props.items.map((item) => ({
                ...item,
                constructor: { ...item.constructor },
              })) || []
            }
            columns={columns}
            isError={props.isError}
            isLoading={props.isLoading}
            error={props?.error || undefined}
            exportData={false}
            selection={true}
          />
        );
      case areOverviewColumns(props.kind, columns) && areOverviewItems(props.kind, props.items): {
        return (
          <GenericExportTable<FleetOverview.Alarm>
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            data={props.items}
            columns={columns}
            exportData={false}
            selection={true}
          />
        );
      }
      default:
        console.error('Invalid kind or columns prop for AlarmsTable');
        return;
    }
  }, [props, columns, t]);

  return (
    <>
      {table}
      {props.slots && props.slots.dialog()}
    </>
  );
}
