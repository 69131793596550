import { TAGS } from '@/redux/slices/operationSlice';
import OneToolVariant from './ByTag/OneTool';
import OTAUpdateVariant from './ByTag/OTAUpdate';
import { CommonSnackbarProps } from './typings';
import DeviceConsumableVariant from './ByTag/DeviceConsumable';
import DeviceTelemetryUpdateVariant from './ByTag/DeviceTelemetryUpdate';
import DeviceCommand from './ByTag/DeviceCommand';
import FleetQNumber from './ByTag/FleetQNumber';
import ContactInfo from './ByTag/ContactInfo';
import DeviceAddressVariant from './ByTag/DeviceAddress';

export default function Snackbar({ operation, dismissAction }: CommonSnackbarProps) {
  if (!operation.tag) {
    return <></>;
  } else if (/^one-tool-/.test(operation.tag)) {
    return <OneToolVariant operation={operation} dismissAction={dismissAction} />;
  } else if (operation.tag === TAGS.OTA_UPDATE) {
    return <OTAUpdateVariant operation={operation} dismissAction={dismissAction} />;
  } else if (/^device-consumable-/.test(operation.tag)) {
    return <DeviceConsumableVariant operation={operation} dismissAction={dismissAction} />;
  } else if (operation.tag === TAGS.DEVICE_TELEMETRY_UPDATE) {
    return <DeviceTelemetryUpdateVariant operation={operation} dismissAction={dismissAction} />;
  } else if (operation.tag === TAGS.DEVICE_COMMAND_SEND) {
    return <DeviceCommand operation={operation} dismissAction={dismissAction} />;
  } else if (operation.tag === TAGS.CONTACT_INFO_UPDATE) {
    return <ContactInfo operation={operation} dismissAction={dismissAction} />;
  } else if (
    operation.tag === TAGS.QNUMBER_CREATE ||
    operation.tag === TAGS.QNUMBER_UPDATE ||
    operation.tag === TAGS.QNUMBER_DELETE
  ) {
    return <FleetQNumber operation={operation} dismissAction={dismissAction} />;
  } else if (/^device-address-/.test(operation.tag)) {
    return <DeviceAddressVariant operation={operation} dismissAction={dismissAction} />;
  }
  return <></>;
}
